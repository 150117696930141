import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Button, ButtonTextStyle, ContentWr, H2, P } from "../../../styles/textTags";
import ApplicationInput from "./ApplicationInput";
import { LOCATIONS_SELECT, PERSONAL_INPUTS } from "../../../features/data";
import { BREAKPOINTS, COLORS } from "../../../styles/variables";
import SvgSelector from "../../Shared/SvgSelector";

import AOS from "aos";
import "aos/dist/aos.css";

import emailjs from "@emailjs/browser";

const PUBLIC_KEY = "Ub0KtqImJsye3q2h6";
const TEMPLATE_ID = "template_ymr3d76";
const SERVICE_ID = "service_d0u5hnd";

const Application = () => {
  const form = useRef();

  const [sentStatus, setSentStatus] = useState("idle");

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [locationError, setLocationError] = useState("");
  const errorRef = useRef(null);

  useEffect(() => {
    AOS.init({
      once: false,
      duration: 700,
      easing: "ease",
      delay: 20,
    });
  }, []);

  const handleChipClick = (location) => {
    if (location.disabled) return;

    if (selectedLocations.includes(location.title)) {
      setSelectedLocations(selectedLocations.filter((loc) => loc !== location.title));
    } else {
      setSelectedLocations([...selectedLocations, location.title]);
    }

    if (selectedLocations.length >= 0) {
      setLocationError("");
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const validateForm = () => {
    if (selectedLocations.length === 0) {
      setLocationError("Необходимо выбрать хотя бы одну локацию!");
      return false;
    } else {
      setLocationError("");
      return true;
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return;
    }

    setSentStatus("sending");

    const myData = {
      fullname: e.target.fullname.value,
      nickname: e.target.nickname.value,
      city: e.target.city.value,
      socials: e.target.socials.value,
      phone: e.target.phone.value,
      email: e.target.email.value,
      sketches: e.target.sketches.value,
      locations: selectedLocations.join(", "),
      concept: e.target.concept.value,
    };

    const templateParams = {
      to_name: "Крутышка",
      message: myData,
    };

    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, templateParams, {
        publicKey: PUBLIC_KEY,
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setSentStatus("sent");
        },
        (error) => {
          console.log("FAILED...", error);
          setSentStatus("error");
        }
      );
  };

  return (
    <ApplicationWr
      id="application"
      data-aos="zoom-y-out"
    >
      <ApplicationContentWr>
        <H2 data-aos="zoom-in">Заявка</H2>
        <Form
          ref={form}
          onSubmit={sendEmail}
        >
          <FormPersonalBlock>
            {PERSONAL_INPUTS.map((el, i) => (
              <ApplicationInput
                key={el.name}
                type={el.type}
                placeholder={el.placeholder}
                name={el.name}
                pattern={el.pattern}
                required={el.required}
              />
            ))}
            <FormNote>
              <h5>ВНИМАНИЕ!</h5>
              <P>Перед отправкой формы проверьте, что доступ к облачному хранилищу открыт для просмотра по ссылке.</P>
            </FormNote>
          </FormPersonalBlock>
          <FormConceptBlock data-aos="fade-left">
            <FormChipsBlock>
              {locationError && <ErrorText ref={errorRef}>{locationError}</ErrorText>}
              <h5>Выберите локации:</h5>

              <ChipContainer>
                {LOCATIONS_SELECT.map((location, index) => (
                  <Chip
                    key={index}
                    selected={selectedLocations.includes(location.title)}
                    disabled={location.disabled}
                    onClick={() => handleChipClick(location)}
                  >
                    {location.title}
                  </Chip>
                ))}
              </ChipContainer>
            </FormChipsBlock>

            <ApplicationInput
              type="textarea"
              placeholder="Описание вашей концепции"
              name="concept"
            />
            <FormAdditionalInfoBlock>
              <a
                href="polozhenie_tochky_sblizhenya.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <SvgSelector svg="paper" />
                положение конкурса
              </a>
              <a
                href="trebovaniya_tochky_sblizhenya.pdf"
                target="_blank"
                rel="noreferrer"
              >
                требования
              </a>
            </FormAdditionalInfoBlock>
            <FormButton
              type="submit"
              disabled={sentStatus === "sent" || sentStatus === "sending"}
            >
              {sentStatus === "sent" ? "форма отправлена!" : sentStatus === "sending" ? "отправка..." : "ОТПРАВИТЬ"}
            </FormButton>
            {sentStatus === "error" && (
              <SendError>
                Произошла ошибка при отправке формы! <br /> Попробуйте снова
              </SendError>
            )}
            <ConsentBlock>
              <ConsentLabel>
                Согласие на обработку персональных данных
                <CheckboxWrapper>
                  <StyledCheckbox
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    required
                  />
                  <CustomCheckbox checked={isChecked}>
                    <SvgSelector svg="check" />
                  </CustomCheckbox>
                </CheckboxWrapper>
              </ConsentLabel>
            </ConsentBlock>
          </FormConceptBlock>
        </Form>
      </ApplicationContentWr>
    </ApplicationWr>
  );
};

const ApplicationWr = styled.section`
  padding: 100px 0 124px;
  background-color: ${COLORS.background.blurRed};
  @media ${BREAKPOINTS.laptop} {
    padding: 7.14vw 0 8.86vw;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 10.31vw 0 11.88vw;
  }
`;

const ApplicationContentWr = styled(ContentWr)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${BREAKPOINTS.laptop} {
    gap: 0.57vw;
  }
  @media ${BREAKPOINTS.mobile} {
    gap: 10vw;
  }
`;

const Form = styled.form`
  display: flex;
  gap: 76px;
  @media ${BREAKPOINTS.laptop} {
    gap: 5.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    gap: 9.38vw;
  }
`;

const FormPersonalBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 562px;

  margin-top: 44px;
  @media ${BREAKPOINTS.laptop} {
    gap: 1.14vw;
    margin-top: 3.14vw;
    width: 40.14vw;
  }
  @media ${BREAKPOINTS.mobile} {
    gap: 2.5vw;
    margin-top: 7.5vw;
    width: 100%;
  }
`;

const FormNote = styled.div`
  margin-top: 47px;
  h5 {
    ${ButtonTextStyle}
    color: ${COLORS.font.dark};
    margin-bottom: 8px;
  }
  p {
    width: 470px;
    font-family: "Wix Madefor Text";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media ${BREAKPOINTS.laptop} {
    margin-top: 3.36vw;
    h5 {
      margin-bottom: 0.57vw;
    }
    p {
      width: 33.57vw;
      font-size: 1.43vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    margin-top: 4.38vw;
    h5 {
      margin-bottom: 2.5vw;
    }
    p {
      width: 100%;
      font-size: 5vw;
    }
  }
`;

const FormConceptBlock = styled.div`
  position: relative;
  width: 562px;
  @media ${BREAKPOINTS.laptop} {
    width: 40.14vw;
  }
  @media ${BREAKPOINTS.mobile} {
    width: 100%;
  }
`;

const FormChipsBlock = styled.div`
  h5 {
    font-family: "Wix Madefor Text";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    color: ${COLORS.font.dark};
    margin-bottom: 19px;
  }
  @media ${BREAKPOINTS.laptop} {
    h5 {
      font-size: 1.43vw;
      margin-bottom: 1.36vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    h5 {
      font-size: 6.25vw;
      margin-bottom: 8.44vw;
    }
  }
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 18px;
  margin-bottom: 18px;
  @media ${BREAKPOINTS.laptop} {
    row-gap: 1.14vw;
    column-gap: 1.29vw;
    margin-bottom: 1.29vw;
  }
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5vw;
    margin-bottom: 9.38vw;
  }
`;

const Chip = styled.div`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  font-family: "Wix Madefor Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  white-space: nowrap;

  border-radius: 33px;
  padding: 5px 16px;

  background-color: ${(props) => (props.selected ? COLORS.background.blue : COLORS.background.white)};
  color: ${(props) => (props.disabled ? COLORS.other.blurRed : props.selected ? COLORS.font.white : COLORS.font.gray)};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  transition:
    background-color 0.2s ease,
    color 0.3s ease;

  &:hover {
    color: ${(props) => (props.disabled ? COLORS.font.red : props.selected ? COLORS.font.white : COLORS.font.dark)};
  }

  @media ${BREAKPOINTS.laptop} {
    font-size: 1vw;
    line-height: 1.71vw;
    border-radius: 2.36vw;
    padding: 0.36vw 1.14vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 4.38vw;
    line-height: 7.5vw;
    border-radius: 10.31vw;
    padding: 1.56vw 5vw;
  }
`;

const ErrorText = styled.p`
  position: absolute;
  top: -40px;
  font-family: "Wix Madefor Text";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${COLORS.font.white};

  font-size: 18px;
  @media ${BREAKPOINTS.laptop} {
    top: -2.86vw;
    font-size: 1.29vw;
  }
  @media ${BREAKPOINTS.mobile} {
    top: -7vw;
    font-size: 4vw;
  }
`;

const SendError = styled.p`
  font-family: "Wix Madefor Text";
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: normal;
  color: ${COLORS.font.white};
  font-size: 18px;
  margin-top: 5px;
  @media ${BREAKPOINTS.laptop} {
    top: -2.86vw;
    font-size: 1.29vw;
    margin-top: 0.36vw;
  }
  @media ${BREAKPOINTS.mobile} {
    top: -7vw;
    font-size: 4vw;
    margin-top: 1.56vw;
  }
`;

const FormAdditionalInfoBlock = styled.div`
  margin-top: 12px;
  display: flex;

  align-items: center;
  justify-content: space-between;
  a {
    font-family: "Wix Madefor Text";
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;

    padding: 14px 27px;

    transition: color 0.2s;
    &:hover {
      color: ${COLORS.font.white};
      svg {
        path {
          fill: ${COLORS.font.white};
        }
      }
    }

    &:first-child {
      display: flex;
      align-items: center;
      gap: 11px;
      svg {
        width: 39px;
        height: 39px;
        path {
          transition: fill 0.2s;
        }
      }
    }
  }
  @media ${BREAKPOINTS.laptop} {
    margin-top: 0.86vw;
    a {
      font-size: 1.43vw;
      padding: 1vw 1.93vw;
      &:first-child {
        gap: 0.79vw;
        svg {
          width: 2.79vw;
          height: 2.79vw;
        }
      }
    }
  }
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    margin-top: 2.5vw;
    a {
      text-decoration: none;
      font-size: 6.25vw;
      padding: 4.38vw 8.44vw;
      &:first-child {
        margin-left: 6vw;
        white-space: normal;
        gap: 3.44vw;
        svg {
          width: 18.19vw;
          height: 18.19vw;
        }
      }
    }
  }
`;

const FormButton = styled(Button)`
  margin-top: 22px;
  background-color: ${COLORS.background.dark};
  width: 100%;

  &:hover {
    color: ${COLORS.font.green};
  }
  &:active {
    color: ${COLORS.font.green};
  }

  &:disabled {
    color: ${COLORS.font.gray};
  }

  @media ${BREAKPOINTS.laptop} {
    margin-top: 1.57vw;
  }
  @media ${BREAKPOINTS.mobile} {
    margin-top: 3.75vw;
    &:hover {
      color: ${COLORS.font.white};
    }
  }
`;

const ConsentBlock = styled.div`
  margin-top: 24px;
  @media ${BREAKPOINTS.laptop} {
    margin-top: 1.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    margin-top: 5vw;
  }
`;

const ConsentLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  color: ${COLORS.font.dark};
  cursor: pointer;

  @media ${BREAKPOINTS.laptop} {
    gap: 0.86vw;
    font-size: 1.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    gap: 18.75vw;
    font-size: 5vw;
    white-space: normal;
  }
`;

const CheckboxWrapper = styled.div`
  position: relative;
  width: 31px;
  height: 31px;
  @media ${BREAKPOINTS.laptop} {
    width: 2.21vw;
    height: 2.21vw;
  }
  @media ${BREAKPOINTS.mobile} {
    width: 9.69vw;
    height: 9.69vw;
  }
`;

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
`;

const CustomCheckbox = styled.div`
  position: relative;
  width: 31px;
  height: 31px;
  background-color: ${COLORS.background.white};

  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 31px;
    height: 31px;
    transition: 0.15s ease;
    transform: scale(${(props) => (props.checked ? "1" : "0")});
  }

  @media ${BREAKPOINTS.laptop} {
    width: 2.21vw;
    height: 2.21vw;
    svg {
      width: 2.21vw;
      height: 2.21vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    width: 9.69vw;
    height: 9.69vw;
    svg {
      width: 9.69vw;
      height: 9.69vw;
    }
  }
`;

export default Application;
