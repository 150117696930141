import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import SvgSelector from '../../Shared/SvgSelector';
import { BREAKPOINTS, COLORS } from '../../../styles/variables';
import { ContentWr, H2 } from '../../../styles/textTags';
import Slide from './Slide';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { POINTS_DATA } from '../../../features/data';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Slider = () => {
  useEffect(() => {
    AOS.init({
      once: false,
      duration: 700,
      easing: 'ease',
      delay: 20,
    });
  }, []);

  return (
    <SliderWr>
      <SliderContentWr>
        <SH2 data-aos='zoom-in'>Точки</SH2>
        <SwiperStyled
          slidesPerView={1}
          slidesPerGroup={1}
          speed={1000}
          spaceBetween={15}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay, Navigation]}
          className='mySwiper'
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          navigation={true}
        >
          {POINTS_DATA.map((slide, index) => (
            <SwiperSlideStyled key={index}>
              <Slide {...slide} />
            </SwiperSlideStyled>
          ))}
        </SwiperStyled>

        <MaterialsBox>
          <SvgWrapper>
            <SvgSelector svg='materials' />
          </SvgWrapper>
          <MaterialsText target='_blank' href='https://disk.yandex.ru/d/LLoC_TRSraNFmQ'>
            Дополнительные материалы о точках
          </MaterialsText>
        </MaterialsBox>
      </SliderContentWr>
    </SliderWr>
  );
};

const SliderWr = styled.section`
  padding-bottom: 100px;
  background-color: ${COLORS.background.light};
  @media ${BREAKPOINTS.laptop} {
    padding-bottom: 7.14vw;
  }
  @media ${BREAKPOINTS.mobile} {
    padding-bottom: 12.5vw;
  }
`;

const SliderContentWr = styled(ContentWr)`
  @media ${BREAKPOINTS.mobile} {
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
  }
`;

const SH2 = styled(H2)`
  margin-bottom: 50px;
  @media ${BREAKPOINTS.laptop} {
    margin-bottom: 3.57vw;
  }
  @media ${BREAKPOINTS.mobile} {
    margin-bottom: 10vw;
    width: 52.5vw;
    margin-left: auto;
    margin-right: auto;
  }
`;

const MaterialsBox = styled.div`
  color: ${COLORS.font.dark};
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 25px auto 0 0;

  @media ${BREAKPOINTS.laptop} {
    gap: 0.57vw;
    margin: 1.786vw auto 0 0;
  }
  @media ${BREAKPOINTS.mobile} {
    gap: 2.5vw;
    margin: 6.25vw auto 0 5.86vw;
    max-width: 80vw;
  }
`;

const MaterialsText = styled.a`
  font-family: 'Wix Madefor Text';
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 1.2;
  text-decoration-line: underline;
  text-transform: uppercase;
  transition: color 0.2s;
  &:hover {
    color: ${COLORS.other.red};
    cursor: pointer;
  }
  &:active {
    color: ${COLORS.other.darlRed};
  }

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 5vw;
    max-width: 55vw;
  }
`;

const SvgWrapper = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  @media ${BREAKPOINTS.laptop} {
    width: 2.86vw;
    height: 2.86vw;
  }

  @media ${BREAKPOINTS.mobile} {
    width: 10vw;
    height: 10vw;
  }
`;

const SwiperSlideStyled = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
`;

const SwiperStyled = styled(Swiper)`
  position: relative;
  width: 1200px;
  height: 600px;

  @media ${BREAKPOINTS.laptop} {
    width: 85.71vw;
    height: 42.86vw;
  }

  @media ${BREAKPOINTS.mobile} {
    width: 87.5vw;
    height: 225vw;
  }

  .swiper-pagination {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: ${COLORS.background.green};
    opacity: 1;
    margin: 0 8px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: ${COLORS.background.dark};
  }
  // .swiper-wrapper {
  //   gap: 20px;
  // }

  .swiper-button-next,
  .swiper-button-prev {
    color: ${COLORS.background.white};

    &::after {
      --swiper-navigation-size: 28px;
    }
  }

  .swiper-button-next {
    width: fit-content;
    height: fit-content;
  }

  .swiper-button-prev {
    width: fit-content;
    height: fit-content;
  }

  @media ${BREAKPOINTS.laptop} {
    .swiper-pagination-bullet {
      width: 1.14vw;
      height: 1.14vw;
      margin: 0 0.57vw !important;
    }
    // .swiper-wrapper {
    //   gap: 1.429vw;
    // }
    .swiper-button-next,
    .swiper-button-prev {
      &::after {
        --swiper-navigation-size: 2vw;
      }
    }

    .swiper-button-next {
    }

    .swiper-button-prev {
    }
  }

  @media ${BREAKPOINTS.mobile} {
    .swiper-pagination {
      bottom: 3.125vw;
    }

    .swiper-pagination-bullet {
      width: 3.125vw;
      height: 3.125vw;
      margin: 0 1.5625vw !important;
    }
    // .swiper-wrapper {
    //   gap: 4.688vw;
    // }
    .swiper-button-next,
    .swiper-button-prev {
      &::after {
        --swiper-navigation-size: 8vw;
      }
    }
  }
`;

export default Slider;
