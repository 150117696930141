import React from "react";
import { styled } from "styled-components";
import { ContentWr, H2, P, PStyle } from "../../styles/textTags";
import { BREAKPOINTS, COLORS } from "../../styles/variables";

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, FreeMode, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { REFEREES_DATA } from "../../features/data";

import "aos/dist/aos.css";

const Referees = () => {
  return (
    <RefereesWr>
      <RefereesContentWr>
        <RefereesH2
          $green
          data-aos="zoom-in"
        >
          Жюри
        </RefereesH2>
        <Gradient1 />
        <SwiperStyled
          slidesPerView={3}
          slidesPerGroup={3}
          speed={1000}
          pagination={{
            clickable: true,
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
        >
          {REFEREES_DATA.map((card, index) => {
            return (
              <SwiperSlideStyled key={index}>
                <RefereesCard>
                  <img
                    src={card.image}
                    alt={card.title}
                  />
                  <h3>{card.title}</h3>
                  <P>{card.desc}</P>
                </RefereesCard>
              </SwiperSlideStyled>
            );
          })}
        </SwiperStyled>
        <Gradient2 />
        <SwiperMobileStyled
          slidesPerView={1.5}
          spaceBetween={0}
          freeMode={true}
          freeModeMomentum={false}
          snapOnRelease={false}
          modules={[FreeMode, Autoplay]}
          className="mySwiper"
        >
          {REFEREES_DATA.map((card, index) => {
            return (
              <SwiperSlideStyled key={index}>
                <RefereesCard>
                  <img
                    src={card.image}
                    alt={card.title}
                  />
                  <h3>{card.title}</h3>
                  <P>{card.desc}</P>
                </RefereesCard>
              </SwiperSlideStyled>
            );
          })}
        </SwiperMobileStyled>
      </RefereesContentWr>
    </RefereesWr>
  );
};

const RefereesWr = styled.section`
  padding: 100px 0;
  @media ${BREAKPOINTS.laptop} {
    padding: 7.14vw 0;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 12.5vw 0;
  }
`;

const RefereesContentWr = styled(ContentWr)`
  position: relative;
  @media ${BREAKPOINTS.mobile} {
    margin-right: 0;
    max-width: 100%;
  }
`;

const RefereesH2 = styled(H2)`
  margin-bottom: 50px;
  @media ${BREAKPOINTS.laptop} {
    margin-bottom: 3.57vw;
  }
  @media ${BREAKPOINTS.mobile} {
    margin-bottom: 10vw;
    width: 52.5vw;
    margin-left: auto;
    margin-right: auto;
  }
`;

const RefereesCard = styled.div`
  width: 264px;
  display: flex;
  flex-direction: column;

  img {
    align-self: center;
    margin-bottom: 25px;
    width: 227px;
    height: 227px;
    border-radius: 50%;
  }

  h3 {
    ${PStyle};
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  @media ${BREAKPOINTS.laptop} {
    width: 18.86vw;
    img {
      margin-bottom: 1.8vw;
      width: 16.21vw;
      height: 16.21vw;
    }
    h3 {
      font-size: 1.43vw;
      line-height: 1.71vw;
      margin-bottom: 0.57vw;
    }
  }

  @media ${BREAKPOINTS.mobile} {
    width: 60.63vw;
    img {
      margin-bottom: 5vw;
      width: 48.75vw;
      height: 48.75vw;
    }
    h3 {
      font-size: 4.38vw;
      line-height: 5.3vw;
      margin-bottom: 2.5vw;
    }
  }
`;

const Gradient1 = styled.div`
  position: absolute;
  width: 126px;
  height: 390px;
  background: linear-gradient(90deg, ${COLORS.background.light} 14.67%, rgba(249, 249, 249, 0) 100%);
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 2;
  @media ${BREAKPOINTS.laptop} {
    width: 9vw;
    height: 27.86vw;
    left: -1.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;
const Gradient2 = styled.div`
  position: absolute;
  width: 126px;
  height: 390px;
  background: linear-gradient(270deg, ${COLORS.background.light} 14.78%, rgba(249, 249, 249, 0) 100%);
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  z-index: 2;
  @media ${BREAKPOINTS.laptop} {
    width: 9vw;
    height: 27.86vw;
    right: -1.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const SwiperSlideStyled = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative;
    margin: 50px 0;
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: ${COLORS.background.green};
    opacity: 1;
    margin: 0 8px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: ${COLORS.background.dark};
  }

  @media ${BREAKPOINTS.laptop} {
    .swiper-pagination {
      margin: 3.57vw 0;
    }

    .swiper-pagination-bullet {
      width: 1.14vw;
      height: 1.14vw;
      margin: 0 0.57vw !important;
    }
  }

  @media ${BREAKPOINTS.mobile} {
    display: none;

    .swiper-pagination {
      margin: 3.57vw;
    }

    .swiper-pagination-bullet {
      width: 1.14vw;
      height: 1.14vw;
      margin: 0 0.57vw !important;
    }
  }
`;

const SwiperMobileStyled = styled(Swiper)`
  display: none;
  @media ${BREAKPOINTS.mobile} {
    display: block;
  }
`;

export default Referees;
