import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { P } from "../../../styles/textTags";
import { BREAKPOINTS, COLORS } from "../../../styles/variables";
import SvgSelector from "../../Shared/SvgSelector";

const Slide = ({ title, address, description, src }) => {
  const [isTapped, setIsTapped] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  const handleTap = () => {
    if (isMobile) {
      setIsTapped(!isTapped);
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 480 && isTapped) {
      setIsTapped(false); // Сбрасываем состояние при переключении на десктоп
    }
    setIsMobile(window.innerWidth < 480);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isTapped]);

  return (
    <SlideContainer onClick={handleTap}>
      <SvgSelectorTap>
        <SvgSelector svg="tap" />
      </SvgSelectorTap>
      <SlideImage
        src={src}
        alt={title}
      />
      <SlideContent $istapped={isTapped}>
        <SlideText>
          <HP>{title}</HP>
          {/* Показываем описание только на мобильных устройствах */}
          {isMobile && isTapped && (
            <DescriptionContainer>
              <Text>
                <TH2>Адрес:</TH2>
                <TP>{address}</TP>
              </Text>
              <Text>
                <TH2>Место:</TH2>
                <TP>{description}</TP>
              </Text>
            </DescriptionContainer>
          )}
          {/* Всегда показываем описание на десктопе */}
          {!isMobile && (
            <>
              <Text>
                <TH2>Адрес:</TH2>
                <TP>{address}</TP>
              </Text>
              <Text>
                <TH2>Место:</TH2>
                <TP>{description}</TP>
              </Text>
            </>
          )}
        </SlideText>
      </SlideContent>
    </SlideContainer>
  );
};

const SlideContainer = styled.div`
  position: relative;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const SlideImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlideContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${COLORS.background.blue};
  transition: height 0.3s ease;
  z-index: 10;
  @media ${BREAKPOINTS.mobile} {
    width: 100%;
    height: ${({ $istapped }) => ($istapped ? "100%" : "auto")};
    padding: 0;
  }
`;

const SlideText = styled.div`
  color: white;
  padding: 50px;
  max-width: 600px;

  @media ${BREAKPOINTS.laptop} {
    padding: 3.57vw;
    max-width: 42.86vw;
  }

  @media ${BREAKPOINTS.mobile} {
    padding: 8.75vw 5vw;
    max-width: 100%;
  }
`;

const HP = styled(P)`
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 20px;
  text-transform: uppercase;

  @media ${BREAKPOINTS.laptop} {
    font-size: 2.29vw;
    margin-bottom: 1.43vw;
  }

  @media ${BREAKPOINTS.mobile} {
    font-weight: bold;
    font-size: 5vw;
    line-height: 6.25vw;
  }
`;

const Text = styled.div`
  margin-bottom: 15px;

  @media ${BREAKPOINTS.laptop} {
    margin-bottom: 1.07vw;
  }

  @media ${BREAKPOINTS.mobile} {
    margin-bottom: 5vw;
    margin-top: 7.5vw;
  }
`;

const TH2 = styled(P)`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 18px;
  text-transform: uppercase;

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.29vw;
    margin-bottom: 0.36vw;
  }

  @media ${BREAKPOINTS.mobile} {
    font-size: 5vw;
    margin-bottom: 2.5vw;
  }
`;

const TP = styled(P)`
  font-size: 16px;

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.14vw;
  }

  @media ${BREAKPOINTS.mobile} {
    font-size: 5vw;
    margin-bottom: 5vw;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DescriptionContainer = styled.div`
  animation: ${fadeIn} 0.3s ease;
`;

const SvgSelectorTap = styled.div`
  position: absolute;
  top: 150px;
  right: 24px;
  width: 57px;
  height: 57px;
  z-index: 9;
  pointer-events: none;
  display: none;
  @media ${BREAKPOINTS.mobile} {
    display: block;
    top: 46.875vw;
    right: 7.5vw;
    width: 17.813vw;
    height: 17.813vw;
  }
`;

export default Slide;
