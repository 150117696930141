import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { ContentWr, H2Style, P } from "../../styles/textTags";
import { BREAKPOINTS, COLORS } from "../../styles/variables";
import SvgSelector from "./SvgSelector";

import img1 from "../../assets/footer/img1.png";
import img2 from "../../assets/footer/img2.png";

import Splitting from "splitting";

import AOS from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  const textFooterRef = useRef(null);

  useEffect(() => {
    AOS.init({
      once: false,
      duration: 500,
      easing: "ease-in-out-quad",
      delay: 50,
    });
  }, []);

  useEffect(() => {
    Splitting({ target: textFooterRef.current });
  }, []);

  return (
    <FooterWr
      id="contacts"
      itemScope
      itemType="https://schema.org/Organization"
    >
      <span
        itemProp="name"
        style={{ position: "absolute", zIndex: "-99", opacity: 0 }}
      >
        PUBLICART
      </span>
      <FooterContentWr>
        <CircleFooterBlock>
          <CircleFooterImg1
            src={img1}
            data-aos="fade-right"
          />
          <CircleFooterImg2
            src={img2}
            data-aos="fade-left"
          />
          <CircleFooterText
            ref={textFooterRef}
            data-aos="zoom-out"
          >
            ТОЧКИ СБЛИЖЕНИЯ. ТОЧКИ СБЛИЖЕНИЯ.
          </CircleFooterText>
        </CircleFooterBlock>

        <FooterContactsBlock>
          <FooterContactsTitle
            itemProp="contactPoint"
            itemScope
            itemType="https://schema.org/ContactPoint"
            data-aos="fade-right"
          >
            связаться с&nbsp;нами
          </FooterContactsTitle>

          <FooterContactsEmailBlock
            itemProp="contactPoint"
            itemScope
            itemType="https://schema.org/ContactPoint"
            data-aos="fade-right"
          >
            <a
              href="mailto:publicart@pushkeen.ru"
              itemProp="email"
            >
              publicart@pushkeen.ru
            </a>
            <p itemProp="description">По любым вопросам</p>
          </FooterContactsEmailBlock>

          {/*<FooterContactsEmailBlock*/}
          {/*  itemProp="contactPoint"*/}
          {/*  itemScope*/}
          {/*  itemType="https://schema.org/ContactPoint"*/}
          {/*  data-aos="fade-right"*/}
          {/*>*/}
          {/*  <a*/}
          {/*    href="mailto:kevler_i@pushkeen.ru"*/}
          {/*    itemProp="email"*/}
          {/*  >*/}
          {/*    kevler_i@pushkeen.ru*/}
          {/*  </a>*/}
          {/*  <p itemProp="description">PR и пресса</p>*/}
          {/*</FooterContactsEmailBlock>*/}

          <FooterContactsEmailMobileBlock
            itemProp="contactPoint"
            itemScope
            itemType="https://schema.org/ContactPoint"
            data-aos="fade-right"
          >
            <a
              href="mailto:publicart@pushkeen.ru"
              itemProp="email"
            >
              publicart
              <br />
              @pushkeen.ru
            </a>
            <p itemProp="description">По любым вопросам</p>
          </FooterContactsEmailMobileBlock>

          {/*<FooterContactsEmailMobileBlock*/}
          {/*  itemProp="contactPoint"*/}
          {/*  itemScope*/}
          {/*  itemType="https://schema.org/ContactPoint"*/}
          {/*  data-aos="fade-right"*/}
          {/*>*/}
          {/*  <a*/}
          {/*    href="mailto:kevler_i@pushkeen.ru"*/}
          {/*    itemProp="email"*/}
          {/*  >*/}
          {/*    kevler_i*/}
          {/*    <br />*/}
          {/*    @pushkeen.ru*/}
          {/*  </a>*/}
          {/*  <p itemProp="description">PR и пресса</p>*/}
          {/*</FooterContactsEmailMobileBlock>*/}

          <FooterContactsSocialBlock>
            <a
              href="https://t.me/publicartspb"
              target="_blank"
              rel="noreferrer"
              itemProp="sameAs"
              data-aos="flip-right"
              data-aos-delay="200"
            >
              <SvgSelector svg="tgLogo" />
            </a>
            <a
              href="https://vk.com/publicartspb"
              target="_blank"
              rel="noreferrer"
              itemProp="sameAs"
              data-aos="flip-right"
              data-aos-delay="350"
            >
              <SvgSelector svg="vkLogo" />
            </a>
          </FooterContactsSocialBlock>
        </FooterContactsBlock>

        <FooterBottomBlock>
          <SvgSelector svg="footerLogo" />
          <P>Проект реализуется при поддержке  Комитета по&nbsp;молодежной политике Санкт-Петербурга</P>
          <a
            href="https://pushkeen.ru/"
            rel="noreferrer"
            target="_blank"
            itemProp="url"
          >
            <P>
              Разработано PushKeen
              <SvgSelector svg="pushkeenLogo" />
            </P>
          </a>
        </FooterBottomBlock>
      </FooterContentWr>
      <FooterBlur />
      <FooterSecondBlur />
    </FooterWr>
  );
};

const FooterWr = styled.footer`
  position: relative;
  overflow: hidden;
  background-color: ${COLORS.background.dark};
  height: 726px;
  width: 100%;
  @media ${BREAKPOINTS.laptop} {
    height: 51.86vw;
  }
  @media ${BREAKPOINTS.mobile} {
    height: 210vw;
  }
`;

const FooterContentWr = styled(ContentWr)`
  z-index: 2;
  position: relative;
  &:before {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    bottom: 10%;
    right: -10%;
    width: 850px;
    height: 850px;
    background-size: cover;
    z-index: 2;
  }
  @media ${BREAKPOINTS.laptop} {
    &:before {
      width: 60.71vw;
      height: 60.71vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    &:before {
      display: none;
    }
  }
`;

const FooterContactsBlock = styled.address`
  position: absolute;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 459px;
  height: 355px;
  top: 100px;
  @media ${BREAKPOINTS.laptop} {
    top: 7.14vw;
    width: 32.79vw;
    height: 25.36vw;
  }
  @media ${BREAKPOINTS.mobile} {
    top: 12.5vw;
    width: 100%;
    height: 115vw;
  }
`;

const FooterContactsTitle = styled.h3`
  ${H2Style}
  padding: 0;
  padding-bottom: 24px;
  @media ${BREAKPOINTS.laptop} {
    padding: 0;
    padding-bottom: 1.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 0;
    padding-bottom: 2.5vw;
    text-align: left;
  }
`;

const FooterContactsEmailBlock = styled.div`
  color: ${COLORS.font.white};
  display: flex;
  flex-direction: column;
  gap: 8px;
  a {
    font-family: "Wix Madefor Text";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: uppercase;
    transition: color 0.2s;
    &:hover {
      color: ${COLORS.other.red};
    }
    &:active {
      color: ${COLORS.other.darlRed};
    }
  }
  p {
    font-family: "Wix Madefor Text";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media ${BREAKPOINTS.laptop} {
    gap: 0.57vw;
    a {
      font-size: 1.71vw;
    }
    p {
      font-size: 1.43vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
    gap: 2.5vw;
    width: 57.81vw;

    a {
      font-size: 6.25vw;
    }
    p {
      font-size: 5vw;
    }
  }
`;

const FooterContactsEmailMobileBlock = styled.div`
  display: none;
  color: ${COLORS.font.white};
  a {
    font-family: "Wix Madefor Text";
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: uppercase;
    &:active {
      color: ${COLORS.other.darlRed};
    }
  }
  p {
    font-family: "Wix Madefor Text";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media ${BREAKPOINTS.mobile} {
    display: flex;
    flex-direction: column;
    gap: 2.5vw;
    width: 57.81vw;
    a {
      font-size: 6.25vw;
    }
    p {
      font-size: 5vw;
    }
  }
`;

const FooterContactsSocialBlock = styled.div`
  padding-top: 28px;
  display: flex;
  gap: 50px;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
    path {
      transition: fill 0.2s;
    }
    &:hover {
      path {
        fill: ${COLORS.other.red};
      }
    }
    &:active {
      path {
        fill: ${COLORS.other.darlRed};
      }
    }
  }
  a {
    cursor: pointer;
    &:first-child {
      width: 39px;
      height: 37px;
    }
    &:last-child {
      width: 50px;
      height: 31px;
    }
  }
  @media ${BREAKPOINTS.laptop} {
    padding-top: 2vw;
    gap: 3.57vw;
    a {
      &:first-child {
        width: 2.79vw;
        height: 2.64vw;
      }
      &:last-child {
        width: 3.57vw;
        height: 2.21vw;
      }
    }
  }
  @media ${BREAKPOINTS.mobile} {
    padding-top: 6.25vw;
    gap: 6.56vw;
    a {
      &:first-child {
        width: 10.94vw;
        height: 10vw;
      }
      &:last-child {
        width: 13.13vw;
        height: 8.13vw;
      }
    }
  }
`;
const FooterBottomBlock = styled.div`
  width: 100%;
  position: absolute;
  bottom: 63px;
  display: flex;
  & > svg {
    margin-right: 65px;
    width: 184px;
    height: 77px;
  }
  p {
    color: ${COLORS.font.white};
    font-weight: 700;
    font-size: 20px;
    &:nth-child(2) {
      width: 486px;
      display: flex;
      align-items: end;
      margin-right: 153px;
    }
  }
  a {
    display: flex;
    p {
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: 23px;
      svg {
        width: 61px;
        height: 53px;
      }
    }
  }

  @media ${BREAKPOINTS.laptop} {
    bottom: 4.5vw;
    & > svg {
      margin-right: 4.64vw;
      width: 13.14vw;
      height: 5.5vw;
    }
    p {
      font-size: 1.43vw;
      &:nth-child(2) {
        width: 34.71vw;
        margin-right: 10.93vw;
      }
    }
    a {
      p {
        gap: 1.64vw;

        svg {
          width: 4.36vw;
          height: 3.79vw;
        }
      }
    }
  }
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    bottom: 5vw;
    gap: 5vw;
    & > svg {
      margin-right: 4.64vw;
      width: 31.25vw;
      height: 13.13vw;
      order: 2;
    }
    p {
      z-index: 2;
      font-size: 5vw;
      &:nth-child(2) {
        width: 70vw;
        margin-right: 10.93vw;
      }
    }
    a {
      order: 3;
      p {
        gap: 10vw;
        svg {
          z-index: 3;
          width: 19.06vw;
          height: 16.56vw;
        }
      }
    }
  }
`;

const FooterBlur = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 518px;
  height: 518px;
  transform: rotate(17deg) translate(50%, -40%);
  border-radius: 50%;
  background-color: ${COLORS.other.blurRed};
  z-index: 1;
  filter: blur(56px);
  -webkit-filter: blur(56px);

  @media ${BREAKPOINTS.laptop} {
    width: 37vw;
    height: 37vw;

    -webkit-filter: blur(4vw);
    filter: blur(4vw);
  }

  @media ${BREAKPOINTS.mobile} {
    width: 54.06vw;
    height: 54.06vw;

    -webkit-filter: blur(16.25vw);
    filter: blur(16.25vw);
    top: auto;
    right: 0;
    bottom: 0;
    transform: rotate(17deg) translate(25%, -33%);
  }
`;

const FooterSecondBlur = styled.div`
  position: absolute;
  border-radius: 50%;
  background-color: ${COLORS.other.blurOrange};

  -webkit-filter: blur(56px);
  filter: blur(56px);
  display: none;
  z-index: 1;

  @media ${BREAKPOINTS.mobile} {
    display: block;
    width: 37.81vw;
    height: 37.81vw;

    -webkit-filter: blur(16.25vw);
    filter: blur(16.25vw);
    right: 0;
    bottom: 0;
    transform: rotate(17deg) translate(60%, -70%);
  }
`;

const CircleFooterBlock = styled.div`
  transform: rotate(-50deg);
  position: absolute;
  right: -60px;
  display: flex;
  align-items: center;
  width: 748px;
  height: 426px;
  top: 28px;

  @media ${BREAKPOINTS.laptop} {
    width: 53.43vw;
    height: 30.43vw;
    right: -4.29vw;
    top: 2vw;
  }

  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const CircleFooterImg1 = styled.img`
  width: 300px;
  height: 280px;
  z-index: 5;
  @media ${BREAKPOINTS.laptop} {
    width: 21.43vw;
    height: 20vw;
  }

  @media ${BREAKPOINTS.mobile} {
    width: 48.75vw;
    height: 45.31vw;
  }
`;

const CircleFooterImg2 = styled.img`
  width: 456px;
  height: 426px;
  z-index: 3;
  @media ${BREAKPOINTS.laptop} {
    width: 32.57vw;
    height: 30.43vw;
  }

  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const CircleFooterText = styled.div`
  z-index: 4;
  position: absolute;
  left: 330px;
  perspective: 1000px;
  perspective-origin: center;

  color: ${COLORS.other.blurRed};

  font-family: Unbounded;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  .word {
    display: flex;
    transform-style: preserve-3d;
    animation: rotate 24s infinite linear;
    justify-content: center;
  }

  .char {
    position: absolute;
    transform: rotateY(calc(var(--char-index) * 12deg)) translateZ(255px);
  }

  &::before {
    content: "";
    position: absolute;
    top: -265px;
    left: -160px;
    width: 130px;
    height: 530px;
    background: radial-gradient(
      circle at center,
      rgba(8, 8, 8, 0.6) 30%,
      rgba(8, 8, 8, 0.6) 55%,
      rgba(8, 8, 8, 0.55) 70%,
      rgba(8, 8, 8, 0.4) 80%,
      rgba(8, 8, 8, 0.2) 90%,
      transparent 100%
    );
    z-index: 1;
    pointer-events: none;
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(90deg) rotateX(20deg) rotateY(360deg);
    }
    100% {
      transform: rotateZ(90deg) rotateX(20deg) rotateY(0deg);
    }
  }

  @media ${BREAKPOINTS.laptop} {
    left: 23.57vw;
    perspective: 71.43vw;
    font-size: 3.71vw;

    .char {
      transform: rotateY(calc(var(--char-index) * 12deg)) translateZ(18.21vw);
    }

    &::before {
      top: -18.93vw;
      left: -11.43vw;
      width: 9.29vw;
      height: 37.86vw;
    }
  }

  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

export default Footer;
