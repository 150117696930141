import React, { useState } from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../../styles/variables";

const ApplicationInput = ({ type = "text", name, placeholder, pattern = ".*", required = false }) => {
  const [value, setValue] = useState("");

  return (
    <InputWrapper data-aos={type === "textarea" ? "fade-left" : "fade-right"}>
      {type === "textarea" ? (
        <StyledTextarea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required={required}
          name={name}
        />
      ) : (
        <StyledInput
          type={type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          name={name}
          id={`${name}-input`}
          pattern={pattern}
          required={required}
        />
      )}

      <PlaceholderLabel
        $type={type}
        htmlFor={`${name}-input`}
        $isEmpty={!value}
      >
        {placeholder}
      </PlaceholderLabel>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 100%;
  height: 49px;
  border-radius: 36px;
  padding: 12px 24px;

  background-color: ${COLORS.background.white};
  color: ${COLORS.font.dark};
  outline: none;
  border: none;
  transition: border-color 0.2s;

  &:invalid {
    outline: 1px solid ${COLORS.background.red};
    color: ${COLORS.font.red};
  }

  @media ${BREAKPOINTS.laptop} {
    height: 3.5vw;
    border-radius: 2.57vw;
    font-size: 1.43vw;
    padding: 0.86vw 1.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    height: 20vw;
    border-radius: 11.25vw;
    font-size: 5vw;
    padding: 3.75vw 7.5vw;
  }
`;

const StyledTextarea = styled.textarea`
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 100%;
  height: 237px;
  resize: none;
  border-radius: 25px;
  padding: 12px 24px;
  background-color: ${COLORS.background.white};
  color: ${COLORS.font.dark};
  outline: none;
  border: none;
  @media ${BREAKPOINTS.laptop} {
    font-size: 1.43vw;
    padding: 0.86vw 1.71vw;
    height: 16.93vw;
    border-radius: 1.39vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 5vw;
    padding: 3.75vw 7.5vw;
    height: 55.94vw;
    border-radius: 7.81vw;
  }
`;

const PlaceholderLabel = styled.label`
  position: absolute;
  left: 24px;

  top: ${(props) => (props.$type === "textarea" ? "12px" : "50%")};
  transform: ${(props) => (props.$type === "textarea" ? "translateY(0)" : "translateY(-50%)")};

  color: ${COLORS.white};
  pointer-events: none;
  white-space: nowrap;
  color: ${COLORS.font.gray};
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;

  transition: opacity 0.2s;
  opacity: ${(props) => (props.$isEmpty ? 1 : 0)};

  @media ${BREAKPOINTS.laptop} {
    left: 1.71vw;
    top: ${(props) => (props.$type === "textarea" ? "0.86vw" : "50%")};
    font-size: 1.11vw;
  }

  @media ${BREAKPOINTS.mobile} {
    left: 7.5vw;
    top: ${(props) => (props.$type === "textarea" ? "3.75vw" : "50%")};
    font-size: 5vw;
    white-space: normal;
  }
`;

export default ApplicationInput;
