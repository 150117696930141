import React, { useEffect } from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../styles/variables";
import { ButtonTextStyle, ContentWr, H2, P } from "../../styles/textTags";
import Gifa1 from "../../assets/aboutus/gifa1.png";
import Gifa2 from "../../assets/aboutus/gifa2.png";

import Splitting from "splitting";

import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    Splitting();
  }, []);

  return (
    <AboutUswWr id="about">
      <AboutUsContentWr>
        <AboutTextBox data-aos="zoom-in">
          <AH2>О проекте</AH2>
          <AboutText>
            <AP>
              <b>Точки сближения</b>&nbsp;&mdash; всероссийский паблик-арт проект, который помогает социальным пространствам обрести
              современный облик и&nbsp;индивидуальность, делает их&nbsp;более инклюзивными и&nbsp;доступными.
              <br />
              <br />
              Совместно со&nbsp;стрит-художниками мы&nbsp;преобразим детские сады, больницы, школы, библиотеки, центры реабилитации, хосписы
              и&nbsp;другие места, где искусство может помочь и&nbsp;стать точкой сближения социальных групп, художников и&nbsp;широкой
              общественности.
            </AP>
          </AboutText>
          <AButton href="#artists">художники проекта</AButton>
        </AboutTextBox>

        <CircleBlock>
          <CircleImg1
            src={Gifa1}
            data-aos="fade-right"
            draggable={false}
          />
          <CircleImg2
            src={Gifa2}
            data-aos="fade-left"
            draggable={false}
          />
          <CircleText
            data-splitting
            data-aos="zoom-out"
          >
            ТОЧКИ СБЛИЖЕНИЯ. ТОЧКИ СБЛИЖЕНИЯ.
          </CircleText>
        </CircleBlock>
      </AboutUsContentWr>
    </AboutUswWr>
  );
};

const AboutUswWr = styled.section`
  width: 100%;
  height: 700px;
  background-color: ${COLORS.background.dark};
  padding-top: 100px;

  @media ${BREAKPOINTS.laptop} {
    height: 50vw;
    padding-top: 7.14vw;
  }

  @media ${BREAKPOINTS.mobile} {
    height: 254.38vw;
  }
`;

const AboutUsContentWr = styled(ContentWr)`
  position: relative;
  display: flex;

  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    justify-content: end;
  }
`;

const AP = styled(P)`
  color: ${COLORS.font.white};
  font-size: 20px;

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.429vw;
  }

  @media ${BREAKPOINTS.mobile} {
    font-size: 5vw;
  }
`;

const AH2 = styled(H2)`
  color: ${COLORS.font.white};
  padding: 0;
  font-size: 24px;
  white-space: nowrap;
  width: fit-content;

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.714vw;
  }

  @media ${BREAKPOINTS.mobile} {
    font-size: 7.5vw;
  }
`;

const AButton = styled.a`
  ${ButtonTextStyle};
  background-color: ${COLORS.background.blue};
  color: ${COLORS.font.white};
  margin-top: 70px;
  width: fit-content;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  padding: 14px 27px;
  transition: 0.3s;
  border-radius: 54px;
  &:hover {
    background: #074197;
  }

  &:active {
    background: #03357f;
  }

  @media ${BREAKPOINTS.laptop} {
    margin-top: 5vw;
    padding: 1vw 1.93vw;
    border-radius: 3.86vw;
  }

  @media ${BREAKPOINTS.mobile} {
    font-size: 5.5vw;
    margin-top: 10vw;
    margin: 12.5vw auto;
    padding: 4.38vw 8.44vw;
    border-radius: 16.88vw;
  }
`;

const AboutTextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 457px;
  @media ${BREAKPOINTS.laptop} {
    align-items: flex-start;
    width: 32.64vw;
  }

  @media ${BREAKPOINTS.mobile} {
    align-items: flex-start;
    width: 100%;
  }
`;

const AboutText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;

  @media ${BREAKPOINTS.laptop} {
    gap: 1.5vw;
    margin-top: 3.5vw;
  }

  @media ${BREAKPOINTS.mobile} {
    gap: 5vw;
    margin-top: 8vw;
  }
`;

const CircleBlock = styled.div`
  position: absolute;
  left: 664px;
  display: flex;
  align-items: center;
  width: 873px;
  height: 457px;
  @media ${BREAKPOINTS.laptop} {
    width: 62.36vw;
    height: 32.64vw;
    left: 47.43vw;
  }

  @media ${BREAKPOINTS.mobile} {
    top: 0;
    left: 0;
    width: 100%;
    height: 57.81vw;
  }
`;

const CircleImg1 = styled.img`
  width: 384px;
  height: 359px;
  z-index: 4;
  @media ${BREAKPOINTS.laptop} {
    width: 27.43vw;
    height: 25.64vw;
  }

  @media ${BREAKPOINTS.mobile} {
    width: 48.75vw;
    height: 45.31vw;
  }
`;

const CircleImg2 = styled.img`
  width: 489px;
  height: 457px;
  z-index: 2;
  @media ${BREAKPOINTS.laptop} {
    width: 34.93vw;
    height: 32.64vw;
  }

  @media ${BREAKPOINTS.mobile} {
    width: 61.88vw;
    height: 57.81vw;
  }
`;

const CircleText = styled.p`
  z-index: 3;
  position: absolute;
  left: 380px;
  perspective: 1000px;
  perspective-origin: center;

  color: ${COLORS.other.blurRed};

  font-family: Unbounded;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  .word {
    display: flex;
    transform-style: preserve-3d;
    animation: rotate 24s infinite linear;
    justify-content: center;
  }

  .char {
    position: absolute;
    transform: rotateY(calc(var(--char-index) * 12deg)) translateZ(260px);
  }

  &::before {
    content: "";
    position: absolute;
    top: -265px;
    left: -140px;
    width: 130px;
    height: 530px;
    background: radial-gradient(
      circle at center,
      rgba(8, 8, 8, 0.6) 30%,
      rgba(8, 8, 8, 0.6) 55%,
      rgba(8, 8, 8, 0.55) 70%,
      rgba(8, 8, 8, 0.4) 80%,
      rgba(8, 8, 8, 0.2) 90%,
      transparent 100%
    );
    z-index: 1;
    pointer-events: none;
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(90deg) rotateX(20deg) rotateY(360deg);
    }
    100% {
      transform: rotateZ(90deg) rotateX(20deg) rotateY(0deg);
    }
  }

  @media ${BREAKPOINTS.laptop} {
    left: 27.14vw;
    perspective: 71.43vw;
    font-size: 3.86vw;

    .char {
      transform: rotateY(calc(var(--char-index) * 12deg)) translateZ(18.57vw);
    }

    &::before {
      top: -18.93vw;
      left: -10vw;
      width: 9.29vw;
      height: 37.86vw;
    }
  }

  @media ${BREAKPOINTS.mobile} {
    left: 49vw;
    perspective: 180vw;
    font-size: 7vw;

    .char {
      transform: rotateY(calc(var(--char-index) * 12deg)) translateZ(32vw);
    }

    &::before {
      top: -31vw;
      left: -14vw;
      width: 11vw;
      height: 63vw;
    }
  }
`;

export default AboutUs;
