import React from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../../styles/variables";
import { H2 } from "../../../styles/textTags";
import { RESULTS_DATA } from "../../../features/data";
import ResultsDropdown from "./ResultsDropdown";

const Results = () => {
  return (
    <ResultsWr>
      <ResultsTitle $green>смотри итоги проекта</ResultsTitle>
      <DropdownsBlock>
        {RESULTS_DATA.map((dropdown, index) => {
          return (
            <ResultsDropdown
              key={index}
              title={dropdown.title}
              content={dropdown.content}
            />
          );
        })}
      </DropdownsBlock>
    </ResultsWr>
  );
};

const ResultsWr = styled.section`
  background-color: ${COLORS.background.white};
`;

const ResultsTitle = styled(H2)`
  border-radius: 0;
  width: 100%;
  padding: 30px 35px;
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${BREAKPOINTS.laptop} {
    padding: 2.14vw 2.5vw;
    font-size: 5.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 3.13vw;
    font-size: 11.25vw;
  }
`;

const DropdownsBlock = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 100%;
  @media ${BREAKPOINTS.laptop} {
    gap: 0.2vw;
  }
  @media ${BREAKPOINTS.mobile} {
    gap: 0.3vw;
  }
`;

export default Results;
