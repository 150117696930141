import styled, { css } from "styled-components";
import { BREAKPOINTS, COLORS } from "./variables";

export const H1Style = css`
  position: absolute;
  opacity: 0;
  z-index: -99;
`;



export const H2Style = css`
  width: fit-content;
  font-family: Unbounded;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 16px 30px;
  border-radius: 47px;
  background-color: ${({ $green }) => ($green ? COLORS.background.green : COLORS.background.dark)};
  color: ${({ $green }) => ($green ? COLORS.font.dark : COLORS.font.white)};

  @media ${BREAKPOINTS.laptop} {
    font-size: 2.29vw;
    padding: 1.14vw 2.14vw;
    border-radius: 3.36vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 7.5vw;
    padding: 5vw 0;
    width: 100%;
    text-align: center;
    border-radius: 14.69vw;
  }
`;

export const PStyle = css`
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    font-weight: 700;
  }

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 5vw;
  }
`;

export const ButtonTextStyle = css`
  font-family: "Wix Madefor Text";
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  color: ${COLORS.font.white};

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 6.25vw;
  }
`;

export const H1 = styled.h1`
  ${H1Style};
`;

export const H2 = styled.h2`
  ${H2Style};
`;

export const P = styled.p`
  ${PStyle};
`;

export const Button = styled.button`
  ${ButtonTextStyle};
  padding: 14px 27px;
  transition: 0.3s;
  border-radius: 54px;
  @media ${BREAKPOINTS.laptop} {
    padding: 1vw 1.93vw;
    border-radius: 3.86vw;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 4.38vw 8.44vw;
    border-radius: 16.88vw;
  }
`;

export const ContentWr = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  @media ${BREAKPOINTS.laptop} {
    max-width: 85.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    max-width: 87.5vw;
  }
`;
