import React from "react";
import { styled } from "styled-components";

import "aos/dist/aos.css";
import { MEDIA_DATA } from "../../../features/data";
import MediaCard from "./MediaCard";
import { BREAKPOINTS, COLORS } from "../../../styles/variables";
import { ContentWr, H2 } from "../../../styles/textTags";

const Media = () => {
  return (
    <MediaWr>
      <MediaContentWr>
        <MediaH2
          $green
          data-aos="zoom-in"
        >
          проект в сми
        </MediaH2>
        <MediaCardsBlock>
          {MEDIA_DATA.map((card, index) => {
            return (
              <MediaCard
                key={index}
                title={card.title}
                link={card.link}
                image={card.image}
              />
            );
          })}
        </MediaCardsBlock>
      </MediaContentWr>
    </MediaWr>
  );
};

const MediaWr = styled.section`
  padding: 100px 0;
  background-color: ${COLORS.background.light};
  @media ${BREAKPOINTS.laptop} {
    padding: 7.14vw 0;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 12.5vw 0;
    margin-top: -75vw;
  }
`;

const MediaContentWr = styled(ContentWr)``;

const MediaH2 = styled(H2)`
  margin-bottom: 50px;
  @media ${BREAKPOINTS.laptop} {
    margin-bottom: 3.57vw;
  }
  @media ${BREAKPOINTS.mobile} {
    margin-bottom: 11.88vw;
    width: 100%;
  }
`;

const MediaCardsBlock = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  @media ${BREAKPOINTS.laptop} {
    gap: 1.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    gap: 7.5vw;
    flex-direction: column;
  }
`;

export default Media;
