import React from 'react';
import { ButtonTextStyle, ContentWr, H2, H2Style, P } from '../../styles/textTags';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../styles/variables';
import img from '../../assets/opencall/1.png';

import 'aos/dist/aos.css';

const OpenCall = () => {
  return (
    <OpenCallWr>
      <ContentWr>
        <TextCon>
          <H2 data-aos='zoom-in'>опен-колл</H2>
          <P>
            Завершился опен-колл для современных художников на создание работ для значимых локаций Санкт-Петербурга.
            <br />
            <br />
            Мы предложили выбрать одно или несколько пространств-участников и разработать концепцию и эскиз паблик-арт проекта. Работа
            должна учитывать контекст места и его этические особенности, а также соответствовать целям проекта «Точки сближения».
            <br />
            <br />
            Эскизы будут отобраны жюри большинством голосов. В состав жюри входят представители социальных площадок, команда PUBLICART,
            спонсоры проекта и искусствоведы.
          </P>
          <Action>
            <div>
              <PAction>7 ЛОКАЦИЙ</PAction>
              <P>В Санкт-Петербурге</P>
            </div>
            <div>
              <PAction>6 НОЯБРЯ</PAction>
              <P>Итоги конкурса</P>
            </div>
          </Action>
        </TextCon>
      </ContentWr>
      <Line name={'Прием заявок'} date={'завершено'} />
      <Line name={'сроки реализации'} date={'с 31 октября по 25 ноября'} />
    </OpenCallWr>
  );
};

const OpenCallWr = styled.section`
  width: 100%;
  background-color: ${COLORS.background.blue};
  color: ${COLORS.font.white};
  position: relative;
  overflow: hidden;
`;

const TextCon = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 32px;
  width: 45%;
  position: relative;
  &:after {
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    bottom: -20%;
    left: 75%;
    width: 1800px;
    height: 1100px;
    background-image: url(${img});
    background-size: cover;
    z-index: 0;
  }
  @media ${BREAKPOINTS.laptop} {
    padding: 3.57vw 0;
    gap: 2.29vw;
    &:after {
      width: 128.57vw;
      height: 78.57vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    width: 100%;
    padding: 12.5vw 0;
    gap: 10vw;
    &:after {
      display: none;
    }
  }
`;

const Action = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    gap: 7.5vw;
  }
`;
const PAction = styled(P)`
  font-size: 36px;
  font-weight: 800;
  @media ${BREAKPOINTS.laptop} {
    font-size: 2.57vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 7.5vw;
  }
`;

const Line = ({ name, date }) => {
  return (
    <LineWr>
      <LineContent data-aos='flip-up' data-aos-duration='200'>
        <Name>{name}</Name>
        <Date>{date}</Date>
      </LineContent>
    </LineWr>
  );
};

const LineWr = styled.section`
  width: 100%;
  background-color: ${COLORS.background.blurRed};
  border-top: 1px solid ${COLORS.background.blue};
  position: relative;
  z-index: 2;
  @media ${BREAKPOINTS.mobile} {
    padding: 3.75vw 0;
    border-top: 0.63vw solid ${COLORS.background.blue};
  }
`;

const LineContent = styled(ContentWr)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${BREAKPOINTS.mobile} {
    align-items: start;
    flex-direction: column;
  }
`;
const Name = styled.h3`
  ${ButtonTextStyle};
  color: ${COLORS.font.dark};
`;
const Date = styled.h4`
  ${H2Style};
  font-size: 24px;
  width: 504px;
  text-align: center;
  margin: 8px 0;
  color: ${COLORS.font.dark};
  background-color: ${COLORS.background.white};
  @media ${BREAKPOINTS.laptop} {
    font-size: 1.71vw;
    margin: 0.57vw 0;
    width: 36vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-family: 'Wix Madefor Text';
    align-items: start;
    font-weight: 800;
    font-size: 5vw;
    margin: 3.75vw 0;
    padding: 3.75vw;
    width: 100%;
  }
`;

export default OpenCall;
