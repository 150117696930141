import ref1 from "../assets/referees/1.png";
import ref2 from "../assets/referees/2.png";
import ref3 from "../assets/referees/3.png";
import ref4 from "../assets/referees/4.png";
import ref5 from "../assets/referees/5.png";
import ref6 from "../assets/referees/6.png";
import ref7 from "../assets/referees/7.png";
import ref8 from "../assets/referees/8.png";
import ref9 from "../assets/referees/9.png";
import ref10 from "../assets/referees/10.png";
import ref11 from "../assets/referees/11.png";
import ref12 from "../assets/referees/12.png";

import Partner1 from "../assets/partners/p11.png";
import Partner2 from "../assets/partners/p22.png";
import Partner3 from "../assets/partners/p33.png";
import Partner4 from "../assets/partners/p44.png";
import Partner5 from "../assets/partners/p55.png";
import Partner6 from "../assets/partners/p66.png";
import Partner7 from "../assets/partners/p77.png";
import Partner8 from "../assets/partners/p88.png";
import Partner9 from "../assets/partners/p99.png";
import Partner10 from "../assets/partners/p10.png";

import Infopartner1 from "../assets/infopartners/i1.png";
import Infopartner2 from "../assets/infopartners/i2.png";
import Infopartner3 from "../assets/infopartners/i3.png";

import slide1Image1 from "../assets/slider/slide1.jpg";
import slide1Image2 from "../assets/slider/slide2.jpg";
import slide1Image3 from "../assets/slider/slide3.jpg";
import slide1Image4 from "../assets/slider/slide4.jpg";
import slide1Image5 from "../assets/slider/slide5.jpg";
import slide1Image6 from "../assets/slider/slide6.jpg";
import { name } from "eslint-plugin-prettier/recommended";

export const GOALS_DATA = [
  {
    number: "01/",
    text: `Преобразим облик социальных учреждений и сделаем их среду более дружелюбной благодаря созданию паблик-арт работ от современных художников со всей страны.`,
  },
  {
    number: "02/",
    text: `Привлечём внимание широкой аудитории, особенно молодёжи, к деятельности социально значимых пространств.`,
  },
  {
    number: "03/",
    text: `Популяризируем современное искусство и его смыслы среди широкой аудитории и создадим среду поддержки и развития молодых авторов.`,
  },
];

export const REFEREES_DATA = [
  {
    image: ref1,
    title: "Кирилл Рейнхольдс",
    desc: (
      <>
        Руководитель креативного агентства <span>PUBLICART</span>
      </>
    ),
  },
  {
    image: ref2,
    title: "Вероника Абашина",
    desc: "Искусствовед",
  },
  {
    image: ref3,
    title: "Стас Багс",
    desc: "Современный художник",
  },
  {
    image: ref4,
    title: "Ефимова Александра",
    desc: "Руководитель арт-студии ПНИ №9",
  },
  {
    image: ref5,
    title: "Борисов Саша",
    desc: "Подопечный интерната, особенный художник",
  },
  {
    image: ref6,
    title: "Степанов Кирилл",
    desc: "Руководитель проекта «Квест погружение история одной зависимости»",
  },
  {
    image: ref7,
    title: "Надежда Каширцева",
    desc: "Заведующая библиотекой «Музей книги блокадного города»",
  },
  {
    image: ref8,
    title: "Кодацкий Никита",
    desc: "Режиссёр проекта «Квест погружение история одной зависимости»",
  },
  {
    image: ref9,
    title: "Синяк Александра",
    desc: "Президент Благотворительного фонда «ДоброДомик»",
  },
  {
    image: ref10,
    title: "Спиридонова Анастасия",
    desc: "Вице-президент Благотворительного фонда «ДоброДомик»",
  },
  {
    image: ref11,
    title: "Ксения Солодова",
    desc: "Координатор спецпроектов «Ночлежки»",
  },
  {
    image: ref12,
    title: "Гридина Мария",
    desc: <>Зам. главврача по связям с&nbsp;общественностью в&nbsp;детском клиническом центре им. К.А. Раухфуса</>,
  },
];

export const PERSONAL_INPUTS = [
  {
    type: "text",
    placeholder: (
      <>
        ФИО<span>*</span>
      </>
    ),
    name: "fullname",
    pattern: ".*",
    required: true,
  },
  {
    type: "text",
    placeholder: "Псевдоним (если есть)",
    name: "nickname",
    pattern: ".*",
    required: false,
  },
  {
    type: "text",
    placeholder: (
      <>
        Город проживания (в&nbsp;настоящее&nbsp;время)<span>*</span>
      </>
    ),
    name: "city",
    pattern: ".*",
    required: true,
  },
  {
    type: "url",
    placeholder: (
      <>
        Ссылка на соц. сети<span>*</span>
      </>
    ),
    name: "socials",
    pattern: ".*",
    required: true,
  },
  {
    type: "tel",
    placeholder: (
      <>
        Телефон<span>*</span>
      </>
    ),
    name: "phone",
    pattern: "\\+?[0-9]{7,15}",
    required: true,
  },
  {
    type: "email",
    placeholder: (
      <>
        Почта<span>*</span>
      </>
    ),
    name: "email",
    pattern: ".*",
    required: true,
  },
  {
    type: "url",
    placeholder: (
      <>
        Cсылка на облачное хранилище&nbsp;(эскизы)<span>*</span>
      </>
    ),
    name: "sketches",
    pattern: ".*",
    required: true,
  },
];

export const POINTS_DATA = [
  {
    title: <>Психоневрологический Интернат № 9</>,
    address: <>Санкт-Петербург, г. Красное Село, ул. Красногородская, 1А</>,
    description: (
      <>
        Один из&nbsp;самых крупных психоневрологических интернатов в&nbsp;Санкт-Петербурге, расположенный на&nbsp;Дудергофских высотах. Его
        подопечные&nbsp;&mdash; люди с&nbsp;психическими нарушениями. Концепция росписи может включать в&nbsp;себя образы от&nbsp;художников
        интерната и&nbsp;погружать в&nbsp;их&nbsp;фантастический мир, где цвета и&nbsp;формы сочетаются самым нетипичным способом. Работы
        местных авторов экспрессивны, они напоминают произведения примитивизма и&nbsp;кубизма, которые меняют привычный взгляд
        на&nbsp;реальность и&nbsp;пересобирают&nbsp;ее.
      </>
    ),
    src: slide1Image1,
  },
  {
    title: <>Детский городской клинический центр им. К. А. Раухфуса</>,
    address: <>Амбулаторно-консультативное отделение , Суворовский пр., 4</>,
    description: (
      <>
        Первая детская больница в&nbsp;Санкт-Петербурге. Тут работала одна из&nbsp;первых русских женщин-врачей А.&nbsp;Н. Шабанова
        и&nbsp;один из&nbsp;основоположников российской школы педиатрии и&nbsp;ларингологии&nbsp;&mdash; К.&nbsp;А. Раухфус. Роспись может
        иметь отсылки к&nbsp;историческим персоналиям и&nbsp;их&nbsp;вкладу, вдохновляться медицинскими и&nbsp;научными открытиям, через
        детскую призму восприятия и&nbsp;отсылать к&nbsp;природному любопытству детей. Цветовая гамма должна сочетаться с&nbsp;историческим
        пространством клиники&nbsp;&mdash; это пастельные, естественные и&nbsp;приглушенные тона.
      </>
    ),
    src: slide1Image2,
  },
  {
    title: <>Ночлежка</>,
    address: <>Санкт-Петербург, ул. Боровая, 112Б</>,
    description: (
      <>
        Старейшая благотворительная организация, оказывающая гуманитарную, социальную и юридическую помощь бездомным. Клиенты Ночлежки
        оказались на улице из-за семейных обстоятельств, конфликтов, мошенничества, потери работы и проблем со здоровьем. Роспись может
        вдохновляться идеей того, что бездомность – это временное состояние, но не клеймо. Образы не должны напрямую транслировать тему
        бездомности, а могут лишь метафорически отталкиваться от нее. Важно, что роспись будет располагаться в общем пространстве для
        клиентов Ночлежки, сотрудников и партнеров организации.
      </>
    ),
    src: slide1Image3,
  },
  {
    title: <>ДоброДомик</>,
    address: <>Санкт-Петербург, ул. Маршала Тухачевского дом, 23</>,
    description: (
      <>
        Кафе с бесплатными обедами для пожилых людей, где они общаются и занимаются творчеством. Это место, которое помогает многим
        пенсионерам не только сэкономить, но и справиться с одиночеством. Многие из посетителей кафе – люди, которые остались одни в пожилом
        возрасте, потеряли близких или были обмануты мошенниками. Роспись может отсылать к уютным и домашним сюжетам из детства художника,
        проведенным с членами семьи.
      </>
    ),
    src: slide1Image4,
  },
  {
    title: <>Библиотека «Музей книги блокадного города»</>,
    address: <>Санкт-Петербург, пр. Юрия Гагарина, 17</>,
    description: (
      <>
        Библиотека Московского района, хранящая и изучающая историю блокады Ленинграда. В ней представлен музей, который собирает печатные
        издания, рукописи, открытки и документы, а также книги, отражающие культурную жизнь города в годы войны. Концепция росписи может
        быть направлена на исторический бэкграунд места, отсылаться к архивным артефактам, обращаться к краеведческим темам района.
      </>
    ),
    src: slide1Image5,
  },
  {
    title: <>Квест-погружение «История одной зависимости» </>,
    address: <>Санкт-Петербург, пер. Угловой, 7</>,
    description: (
      <>
        Квест рассказывает историю о борьбе с наркотической зависимостью в современных реалиях. Это путь, проходящий через локации «притона»
        и мрачных подъездов, отделение полиции, психбольницу и медицинские кабинеты. Роспись может стать частью квеста, отражать атмосферу
        уличного сквота и темных городских закоулков с помощью надписей и образов, которые разговаривают с участниками.
      </>
    ),
    src: slide1Image6,
  },
];

export const PARTNERS_DATA = [
  { image: Partner1, alt: "Мы Петербург" },
  { image: Partner2, alt: "VINCORE" },
  { image: Partner9, alt: "Артон" },
  { image: Partner10, alt: "Graffiti Market" },
  { image: Partner3, alt: "Ночлежка" },
  { image: Partner4, alt: "Добродомик" },
  { image: Partner5, alt: "Partner 5" },
  { image: Partner6, alt: "Библиотека" },
  { image: Partner7, alt: "Partner 7" },
  { image: Partner8, alt: "ПНИ №9" },
];

export const INFOPARTNERS_DATA = [
  { image: Infopartner1, alt: "Ревизор" },
  { image: Infopartner2, alt: "Культура Петербурга" },
  { image: Infopartner3, alt: "АртУзел" },
];

export const ARTISTS_DATA = [
  {
    title: "Пнёва Дарья",
    desc: (
      <>
        Психоневрологический интернат №9
      </>
    ),
  },
  {
    title: "Сергей RUSICH",
    desc: (
      <>
        Детский городской клинический центра им. К. А. Раухфуса
      </>
    ),
  },
  {
    title: "Куневич Артём",
    desc: (
      <>
        Ночлежка
      </>
    ),
  },
  {
    title: "Kirill_Note",
    desc: (
      <>
        ДоброДомик
      </>
    ),
  },
  {
    title: "Kibeda",
    desc: (
      <>
        Библиотека «Музей книги блокадного города»
      </>
    ),
  },
  {
    title: "Саша64",
    desc: (
      <>
        Квест-погружение  «История одной зависимости»
      </>
    ),
  },
  {
    title: "Vovаs",
    desc: (
      <>
        Подростково-молодежный клуб «Шахматный»
      </>
    ),
  },


];

export const MEDIA_DATA = [
  {
    title: "Заголовок1",
    link: { name: "Источник", href: "https://caniuse.com/" },
    image: Partner1,
  },
  {
    title: "Заголовок2",
    link: { name: "Источник", href: "https://caniuse.com/" },
    image: Partner2,
  },
  {
    title: "Заголовок3",
    link: { name: "Источник", href: "https://caniuse.com/" },
    image: Partner3,
  },
];

export const RESULTS_DATA = [
  {
    title: "Психоневрологический интернат №9",
    content: {
      address: (
        <>
          Санкт-Петербург <br />
          Лиговский пр., 8
        </>
      ),
      cards: [
        {
          image: Partner1,
          title: "О локации",
          paragraph:
            "Один из самых крупных психоневрологических интернатов в Санкт-Петербурге, расположенный на Дудергофских высотах. Его подопечные — люди с психическими нарушениями. Концепция росписи может включать в себя образы от художников интерната и погружать в их фантастический мир, где цвета и формы сочетаются самым нетипичным способом. Работы местных авторов экспрессивны, они напоминают произведения примитивизма и кубизма, которые меняют привычный взгляд на реальность и пересобирают ее.",
        },
        {
          image: Partner2,
          title: "Иванова Мария",
          paragraph:
            "Это мурал в ярких цветах, размером около 10 fsfd метров в высоту и 5 метров в ширину. На нем fdsf изображены различные природные элементы:dfs  цветущие деревья, загадочные леса, и подводный мир с яркими рыбами и кораллами. В то же время, художник использует контрастные темные dsfsdf элементы, такие как дым, пластиковые отходы и урбанистические структуры, которые  sdfs dsf подчеркивают негативное воздействие человека на природу.",
        },
      ],
      slider: [Partner1, Partner2, Partner3, Partner4, Partner5],
    },
  },
  {
    title: "Ночлежка",
    content: {
      address: (
        <>
          Санкт-Петербург <br />
          Лиговский пр., 8
        </>
      ),
      cards: [
        {
          image: Partner1,
          title: "О локации",
          paragraph:
            "Один из самых крупных психоневрологических интернатов в Санкт-Петербурге, расположенный на Дудергофских высотах. Его подопечные — люди с психическими нарушениями. Концепция росписи может включать в себя образы от художников интерната и погружать в их фантастический мир, где цвета и формы сочетаются самым нетипичным способом. Работы местных авторов экспрессивны, они напоминают произведения примитивизма и кубизма, которые меняют привычный взгляд на реальность и пересобирают ее.",
        },
        {
          image: Partner2,
          title: "Иванова Мария",
          paragraph:
            "Это мурал в ярких цветах, размером около 10 fsfd метров в высоту и 5 метров в ширину. На нем fdsf изображены различные природные элементы:dfs  цветущие деревья, загадочные леса, и подводный мир с яркими рыбами и кораллами. В то же время, художник использует контрастные темные dsfsdf элементы, такие как дым, пластиковые отходы и урбанистические структуры, которые  sdfs dsf подчеркивают негативное воздействие человека на природу.",
        },
      ],
      slider: [Partner1, Partner2, Partner3, Partner4, Partner5],
    },
  },
  {
    title: "Детская клиника  им. К. А. Раухфуса",
    content: {
      address: (
        <>
          Санкт-Петербург <br />
          Лиговский пр., 8
        </>
      ),
      cards: [
        {
          image: Partner1,
          title: "О локации",
          paragraph:
            "Один из самых крупных психоневрологических интернатов в Санкт-Петербурге, расположенный на Дудергофских высотах. Его подопечные — люди с психическими нарушениями. Концепция росписи может включать в себя образы от художников интерната и погружать в их фантастический мир, где цвета и формы сочетаются самым нетипичным способом. Работы местных авторов экспрессивны, они напоминают произведения примитивизма и кубизма, которые меняют привычный взгляд на реальность и пересобирают ее.",
        },
        {
          image: Partner2,
          title: "Иванова Мария",
          paragraph:
            "Это мурал в ярких цветах, размером около 10 fsfd метров в высоту и 5 метров в ширину. На нем fdsf изображены различные природные элементы:dfs  цветущие деревья, загадочные леса, и подводный мир с яркими рыбами и кораллами. В то же время, художник использует контрастные темные dsfsdf элементы, такие как дым, пластиковые отходы и урбанистические структуры, которые  sdfs dsf подчеркивают негативное воздействие человека на природу.",
        },
      ],
      slider: [Partner1, Partner2, Partner3, Partner4, Partner5],
    },
  },
  {
    title: "Библиотека",
    content: {
      address: (
        <>
          Санкт-Петербург <br />
          Лиговский пр., 8
        </>
      ),
      cards: [
        {
          image: Partner1,
          title: "О локации",
          paragraph:
            "Один из самых крупных психоневрологических интернатов в Санкт-Петербурге, расположенный на Дудергофских высотах. Его подопечные — люди с психическими нарушениями. Концепция росписи может включать в себя образы от художников интерната и погружать в их фантастический мир, где цвета и формы сочетаются самым нетипичным способом. Работы местных авторов экспрессивны, они напоминают произведения примитивизма и кубизма, которые меняют привычный взгляд на реальность и пересобирают ее.",
        },
        {
          image: Partner2,
          title: "Иванова Мария",
          paragraph:
            "Это мурал в ярких цветах, размером около 10 fsfd метров в высоту и 5 метров в ширину. На нем fdsf изображены различные природные элементы:dfs  цветущие деревья, загадочные леса, и подводный мир с яркими рыбами и кораллами. В то же время, художник использует контрастные темные dsfsdf элементы, такие как дым, пластиковые отходы и урбанистические структуры, которые  sdfs dsf подчеркивают негативное воздействие человека на природу.",
        },
      ],
      slider: [Partner1, Partner2, Partner3, Partner4, Partner5],
    },
  },
  {
    title: "Добродомик",
    content: {
      address: (
        <>
          Санкт-Петербург <br />
          Лиговский пр., 8
        </>
      ),
      cards: [
        {
          image: Partner1,
          title: "О локации",
          paragraph:
            "Один из самых крупных психоневрологических интернатов в Санкт-Петербурге, расположенный на Дудергофских высотах. Его подопечные — люди с психическими нарушениями. Концепция росписи может включать в себя образы от художников интерната и погружать в их фантастический мир, где цвета и формы сочетаются самым нетипичным способом. Работы местных авторов экспрессивны, они напоминают произведения примитивизма и кубизма, которые меняют привычный взгляд на реальность и пересобирают ее.",
        },
        {
          image: Partner2,
          title: "Иванова Мария",
          paragraph:
            "Это мурал в ярких цветах, размером около 10 fsfd метров в высоту и 5 метров в ширину. На нем fdsf изображены различные природные элементы:dfs  цветущие деревья, загадочные леса, и подводный мир с яркими рыбами и кораллами. В то же время, художник использует контрастные темные dsfsdf элементы, такие как дым, пластиковые отходы и урбанистические структуры, которые  sdfs dsf подчеркивают негативное воздействие человека на природу.",
        },
      ],
      slider: [Partner1, Partner2, Partner3, Partner4, Partner5],
    },
  },
  {
    title: "Квест-погружение",
    content: {
      address: (
        <>
          Санкт-Петербург <br />
          Лиговский пр., 8
        </>
      ),
      cards: [
        {
          image: Partner1,
          title: "О локации",
          paragraph:
            "Один из самых крупных психоневрологических интернатов в Санкт-Петербурге, расположенный на Дудергофских высотах. Его подопечные — люди с психическими нарушениями. Концепция росписи может включать в себя образы от художников интерната и погружать в их фантастический мир, где цвета и формы сочетаются самым нетипичным способом. Работы местных авторов экспрессивны, они напоминают произведения примитивизма и кубизма, которые меняют привычный взгляд на реальность и пересобирают ее.",
        },
        {
          image: Partner2,
          title: "Иванова Мария",
          paragraph:
            "Это мурал в ярких цветах, размером около 10 fsfd метров в высоту и 5 метров в ширину. На нем fdsf изображены различные природные элементы:dfs  цветущие деревья, загадочные леса, и подводный мир с яркими рыбами и кораллами. В то же время, художник использует контрастные темные dsfsdf элементы, такие как дым, пластиковые отходы и урбанистические структуры, которые  sdfs dsf подчеркивают негативное воздействие человека на природу.",
        },
      ],
      slider: [Partner1, Partner2, Partner3, Partner4, Partner5],
    },
  },
];

export const LOCATIONS_SELECT = [
  { title: "Интернат №9", disabled: false },
  { title: "Клиника", disabled: false },
  { title: "Библиотека", disabled: false },
  { title: "Квест-погружение", disabled: false },
  { title: "Будет позже", disabled: true },
  { title: "Ночлежка", disabled: false },
  { title: "Будет позже", disabled: true },
  { title: "ДоброДомик", disabled: false },
  { title: "Будет позже", disabled: true },
];

export const NAV_LINKS = [
  {
    name: "О проекте",
    href: "#about",
  },
  {
    name: "Художники проекта",
    href: "#artists",
  },
  {
    name: "Контакты",
    href: "#contacts",
  },
];
