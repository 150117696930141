import { createGlobalStyle } from 'styled-components';
import { BREAKPOINTS, COLORS } from './variables';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;  
    //border: 1px pink solid;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    scroll-behavior: smooth;
    scroll-padding: 0;
    scroll-padding-top: 100px;
    @media ${BREAKPOINTS.laptop} {
      scroll-padding-top: 7.14vw;
    }
    @media ${BREAKPOINTS.laptop} {
      scroll-padding-top: 18vw;
    }
  }

  body {
    width: 100%;
    overflow-x: hidden;
    max-width: 100vw;
    background-color: ${COLORS.background.light};
    color: ${COLORS.font.dark};
  }
  
  main {
    overflow-x: hidden;
    max-width: 100vw;
  }

  section {
    overflow: hidden;
  }

  p,
  a,
  br,
  span,
  img,
  div,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input,
  textarea {
    color: ${COLORS.white};
  }

  button {
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    color: inherit;
  }

  a {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: transparent;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

export default GlobalStyles;
