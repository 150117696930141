import React, { useState } from 'react';
import styled from 'styled-components';
import { ContentWr } from '../../styles/textTags';
import { BREAKPOINTS, COLORS } from '../../styles/variables';
import SvgSelector from './SvgSelector';
import { NAV_LINKS } from '../../features/data';

const Header = () => {
  const [burgerIsOpen, setBurgerIsOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <HeaderWr>
        <HeaderContentWr>
          <span onClick={scrollToTop}>
            <SvgSelector svg='headerLogo' />
          </span>

          <HeaderNavBlock>
            <ul>
              {NAV_LINKS.map((link, index) => {
                return (
                  <li key={index}>
                    <a href={link.href}>{link.name}</a>
                  </li>
                );
              })}
            </ul>
          </HeaderNavBlock>
        </HeaderContentWr>
      </HeaderWr>

      <HeaderMobileWr $burgerisopen={burgerIsOpen}>
        {burgerIsOpen && <Backdrop onClick={() => setBurgerIsOpen(false)} onTouchStart={() => setBurgerIsOpen(false)} />}

        <span onClick={scrollToTop}>
          <SvgSelector svg='headerLogo' />
        </span>

        <BurgerBtn onClick={() => setBurgerIsOpen((state) => !state)} $burgerisopen={burgerIsOpen}>
          <SvgSelector svg='burger' />
        </BurgerBtn>

        <HeaderNavBlock $burgerisopen={burgerIsOpen}>
          {NAV_LINKS.map((link, i) => {
            return (
              <a
                key={`nav_mobile_link_${i}`}
                href={link.href}
                onClick={() => setBurgerIsOpen(false)}
                style={{
                  transition: '0.5s',
                  transform: `translateX(${burgerIsOpen ? 0 : -500}px)`,
                  opacity: burgerIsOpen ? 1 : 0,
                  transitionDelay: `${(i) * 100}ms`,
                }}
              >
                {link.name}
              </a>
            );
          })}
        </HeaderNavBlock>
      </HeaderMobileWr>
    </>
  );
};

const HeaderWr = styled.header`
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100px;
  background-color: ${COLORS.background.dark};

  color: ${COLORS.font.white};
  font-family: 'Wix Madefor Text';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media ${BREAKPOINTS.laptop} {
    height: 7.14vw;
    font-size: 1.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const HeaderMobileWr = styled.header`
  display: none;
  min-height: 18vw;
  z-index: 99;
  background-color: transparent;
  color: ${COLORS.font.white};
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${COLORS.background.dark};

  font-family: 'Wix Madefor Text';
  font-size: 6.25vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  @media ${BREAKPOINTS.mobile} {
    display: block;
    & > span {
      cursor: pointer;
      display: flex;
      align-items: center;

      width: 26.88vw;
      height: 11.25vw;

      transition: opacity 0.2s;
      transition-delay: ${(props) => (props.$burgerisopen ? '0' : '0.4s')};

      opacity: ${(props) => (props.$burgerisopen ? '0' : '1')};
      position: absolute;
      z-index: 101;
      top: 4vw;
      left: 6.25vw;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const Backdrop = styled.div`
  z-index: 98;
  position: fixed;

  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.background.dark};
  opacity: 0.4;
`;

const HeaderContentWr = styled(ContentWr)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    width: 86px;
    height: 36px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media ${BREAKPOINTS.laptop} {
    span {
      width: 6.14vw;
      height: 2.57vw;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const HeaderNavBlock = styled.nav`
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 93px;
    a {
      transition: color 0.2s;
    }

    a:hover {
      color: ${COLORS.font.green};
    }
    a:active {
      color: ${COLORS.font.darkGreen};
    }
  }

  @media ${BREAKPOINTS.laptop} {
    ul {
      gap: 6.64vw;
    }
  }

  @media ${BREAKPOINTS.mobile} {
    z-index: 100;
    background-color: black;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%;
    transition: 0.5s;
    height: ${(props) => (props.$burgerisopen ? '61.88vw' : '0')};
    background-color: ${(props) => (props.$burgerisopen ? COLORS.white : 'auto')};
    a {
      transition: color 0.1s;
      display: flex;
      justify-content: start;
      align-items: center;
      padding-left: 6.25vw;
      width: 70%;
      height: 13.13vw;
      font-weight: 600;
      font-size: 4.38vw;
      &:active {
        color: ${COLORS.font.darkGreen};
      }
    }
  }
`;

const BurgerBtn = styled.button.attrs({ type: 'button' })`
  position: absolute;
  z-index: 101;
  top: 6.25vw;
  right: 6.25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.1vw;
  height: 5vw;
  svg {
    width: 100%;
    height: 100%;
    rect {
      fill: ${(props) => props.$burgerisopen && COLORS.black};
    }
    #first {
      transform-origin: 50%;
      transform: rotate(${(props) => (props.$burgerisopen ? '45deg' : '0deg')})
        translatey(${(props) => (props.$burgerisopen ? '37%' : '0px')});
      transition: 0.5s;
    }

    #second {
      width: ${(props) => (props.$burgerisopen ? '0' : '6vw')};
      transform: translateX(${(props) => (props.$burgerisopen ? '3vw' : '0px')});
      transition: 0.5s;
    }

    #third {
      transform-origin: 50%;
      transform: rotate(${(props) => (props.$burgerisopen ? '-45deg' : '0deg')})
        translatey(${(props) => (props.$burgerisopen ? '-37%' : '0')});
      transition: 0.5s;
    }
  }
`;

export default Header;
