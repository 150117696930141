import React from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../../styles/variables";
import { ContentWr, H2, H2Style, P } from "../../../styles/textTags";
import WGirl from "../../../assets/winners/wgirl.png";

import "aos/dist/aos.css";
import ParallaxWinners from "./Parallax";
import SvgSelector from "../../Shared/SvgSelector";

const Index = () => {
  return (
    <WinnersWr>
      <WinnersContentWr>
        <WLeft>
          <WH2 data-aos="zoom-in">Победитель получает:</WH2>
        </WLeft>
        <WRight>
          <WUl>
            <Wli
              data-aos="fade-left"
              data-aos-delay="50"
            >
              <WPoint>01/</WPoint>
              <WP>
                Гонорар от&nbsp;<b>35.000 руб.</b> до&nbsp; <b>55.000 руб.</b>
                <br /> на&nbsp;реализацию работы
              </WP>
            </Wli>
            <Wli
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <WPoint>02/</WPoint>
              <WP>
                Пространство для создания росписи от&nbsp; <b>15</b>&nbsp;до&nbsp;<b>70</b>
                &nbsp;кв.м
              </WP>
            </Wli>
            <Wli
              data-aos="fade-left"
              data-aos-delay="150"
            >
              <WPoint>03/</WPoint>
              <WP>Необходимые материалы для создания работы</WP>
            </Wli>
            <Wli
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <WPoint>04/</WPoint>
              <WP>Информационную поддержку в&nbsp;социальных сетях и&nbsp;СМИ</WP>
            </Wli>
            <Wli
              data-aos="fade-left"
              data-aos-delay="250"
            >
              <WPoint>05/</WPoint>
              <WP>Фото и&nbsp;видеосъемку процесса и&nbsp;результата работы, которое можно поместить в&nbsp;портфолио</WP>
            </Wli>
            <Wli
              data-aos="fade-left"
              data-aos-delay="300"
            >
              <WPoint>06/</WPoint>
              <WP>
                Возможность стать резидентом лавки <b>СЕТКА</b> и&nbsp;продолжить сотрудничество с&nbsp;агентством <b>PUBLICART</b>
              </WP>
            </Wli>
          </WUl>
          <RegulationsLink
            href="polozhenie_tochky_sblizhenya.pdf"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-left"
            data-aos-delay="350"
          >
            <SvgSelector svg="paper" />
            положение конкурса
          </RegulationsLink>
        </WRight>
        <ParallaxWinners />
        {/*<WImage src={WGirl} />*/}
      </WinnersContentWr>
    </WinnersWr>
  );
};

const WinnersWr = styled.section`
  position: relative;
  width: 100%;
  background-color: ${COLORS.background.light};
  overflow: hidden;
  margin-bottom: 50px;

  @media ${BREAKPOINTS.laptop} {
    margin-bottom: 3.571vw;
  }

  @media ${BREAKPOINTS.mobile} {
    margin: 12.5vw 0;
    height: auto;
  }
`;

const WinnersContentWr = styled(ContentWr)`
  display: flex;
  padding: 50px 0;
  flex-direction: row;
  position: relative;
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 25vw;
  }
`;

const WLeft = styled.div`
  position: relative;
  flex: 1;
  flex-shrink: 0;
  z-index: 2;
  @media ${BREAKPOINTS.mobile} {
    margin-right: 0;
    margin-bottom: 6.25vw;
  }
`;

const WRight = styled.div`
  position: relative;
  flex: 1;
  padding: 150px 0 0 70px;
  z-index: 2;
  @media ${BREAKPOINTS.laptop} {
    padding: 10.714vw 0 0 5vw;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 0;
  }
`;

const WP = styled(P)`
  max-width: 410px;
  @media ${BREAKPOINTS.laptop} {
    max-width: 29.286vw;
  }

  @media ${BREAKPOINTS.mobile} {
    max-width: 62.5vw;
  }
`;

const WH2 = styled(H2)`
  z-index: 2;
  white-space: nowrap;
  @media ${BREAKPOINTS.mobile} {
    position: static;
    white-space: normal;
  }
`;

// const WImage = styled.img`
//   position: absolute;
//   z-index: 1;
//   width: 100%;
//   height: auto;
//   max-height: 100%;
//   object-fit: contain;
//   left: -20%;
//   bottom: -5%;
//   pointer-events: none;
//   @media ${BREAKPOINTS.laptop} {
//     width: 82.857vw;
//     height: auto;
//   }
//   @media ${BREAKPOINTS.mobile} {
//     display: none;
//   }
// `;

const WUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 30px;
  list-style: none;
  padding: 0;
  margin: 0;

  @media ${BREAKPOINTS.laptop} {
    gap: 3.571vw;
  }

  @media ${BREAKPOINTS.mobile} {
    gap: 6.25vw;
  }
`;

const Wli = styled.li`
  display: flex;
  align-items: center;
  gap: 14px;

  @media ${BREAKPOINTS.mobile} {
    flex-direction: row;
  }
`;

const WPoint = styled.span`
  ${H2Style};
  color: ${COLORS.font.dark};
  background-color: ${COLORS.font.green};
  font-size: 20px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.43vw;
    width: 4.571vw;
    height: 4.571vw;
  }

  @media ${BREAKPOINTS.mobile} {
    font-size: 6.25vw;
    width: 20vw;
    height: 20vw;
  }
`;

const RegulationsLink = styled.a`
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  transition: color 0.2s;
  margin-top: 50px;
  margin-left: 14px;

  display: flex;
  align-items: center;
  gap: 11px;
  &:hover {
    color: ${COLORS.font.red};
    svg {
      path {
        transition: fill 0.2s;
        fill: ${COLORS.font.red};
      }
    }
  }

  &:first-child {
    display: flex;
    align-items: center;
    gap: 11px;
    svg {
      width: 39px;
      height: 39px;
      path {
        transition: fill 0.2s;
      }
    }
  }

  @media ${BREAKPOINTS.laptop} {
    a {
      font-size: 1.43vw;
      padding: 1vw 1.93vw;
      &:first-child {
        gap: 0.79vw;
        svg {
          width: 2.79vw;
          height: 2.79vw;
        }
      }
    }
  }
  @media ${BREAKPOINTS.mobile} {
    a {
      text-decoration: none;
      font-size: 6.25vw;
      padding: 4.38vw 8.44vw;
      &:first-child {
        margin-left: 6vw;
        white-space: normal;
        gap: 3.44vw;
        svg {
          width: 18.19vw;
          height: 18.19vw;
        }
      }
    }
  }
`;

export default Index;
