import React from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../../../styles/variables";

const ResultsCard = ({ title, image, paragraph }) => {
  return (
    <ResultsCardWr>
      <CardImg
        src={image}
        alt={title}
      />
      <CardTitle>{title}</CardTitle>
      <CardParapgraph>{paragraph}</CardParapgraph>
    </ResultsCardWr>
  );
};

const ResultsCardWr = styled.div`
  width: 585px;
  border-radius: 24px;
  padding: 40px 49px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: ${COLORS.background.white};
  @media ${BREAKPOINTS.laptop} {
    width: 41.79vw;
    border-radius: 1.71vw;
    padding: 2.86vw 3.5vw;
  }
  @media ${BREAKPOINTS.mobile} {
    align-items: center;
    width: 100%;
    border-radius: 7.5vw;
    padding: 6.25vw;
  }
`;

const CardImg = styled.img`
  width: 184px;
  height: 172px;
  object-fit: cover;
  margin-bottom: 35px;
  @media ${BREAKPOINTS.laptop} {
    width: 13.14vw;
    height: 12.29vw;
    margin-bottom: 2.5vw;
  }
  @media ${BREAKPOINTS.mobile} {
    width: 38.75vw;
    height: 40.31vw;
    margin-bottom: 8.75vw;
  }
`;

const CardTitle = styled.h6`
  font-family: Unbounded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 24px;
  @media ${BREAKPOINTS.laptop} {
    font-size: 1.71vw;
    margin-bottom: 1.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 6.25vw;
    margin-bottom: 6.25vw;
  }
`;

const CardParapgraph = styled.p`
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${BREAKPOINTS.laptop} {
    font-size: 1.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 5vw;
  }
`;

export default ResultsCard;
