import React from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../styles/variables";
import { ContentWr, H2, P } from "../../styles/textTags";
import CGrafiti from "../../assets/conception/cgrafiti.png";

import "aos/dist/aos.css";

const Conception = () => {
  return (
    <ConceptionWr>
      <CImage
        src={CGrafiti}
        alt="Граффити"
      />
      <WinnersContentWr>
        <CH2 data-aos="zoom-in">Концепция</CH2>

        <ConceptionTextBox>
          <CP data-aos="fade-right">
            <b>Паблик-арт</b>&nbsp;&mdash; это одно из&nbsp;самых понятных и&nbsp;доступных широкой аудитории искусств. Это универсальный
            язык, на&nbsp;котором говорит современный город. В&nbsp;ходе проекта мы&nbsp;ответим на&nbsp;вопросы: может&nbsp;ли паблик-арт
            проникать в&nbsp;социальные пространства? Какой должна быть идентичность социальных проcтранств сегодня? Как её&nbsp;можно
            модернизировать и&nbsp;улучшить с&nbsp;помощью искусства? Как сделать особенное место для особенных социальных групп?
          </CP>
          <CP data-aos="fade-left">
            Мы&nbsp;найдём точки сближения с&nbsp;посетителями социальных пространств через искусство и&nbsp;создадим работы, которые
            помогают им&nbsp;адаптироваться в&nbsp;современном мире, настраивают на&nbsp;открытость и&nbsp;со-творчество.
            <br />
            <br />
            Росписи сформируют заботливый микроклимат, где каждый сможет чувствовать себя самим собой, они выявят уникальность отдельного
            места, расскажут его историю и&nbsp;покажут его значимость широкой аудитории.
          </CP>
        </ConceptionTextBox>
      </WinnersContentWr>
    </ConceptionWr>
  );
};

const ConceptionWr = styled.section`
  position: relative;
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${COLORS.background.dark};
  position: relative;
  overflow: hidden;
  @media ${BREAKPOINTS.laptop} {
    height: 42.857vw;
  }

  @media ${BREAKPOINTS.mobile} {
    height: auto;
    padding-bottom: 6.25vw;
  }
`;

const WinnersContentWr = styled(ContentWr)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CH2 = styled(H2)`
  color: ${COLORS.font.dark};
  background-color: ${COLORS.background.green};
  margin-top: 100px;
  white-space: nowrap;

  @media ${BREAKPOINTS.laptop} {
    margin-top: 7.143vw;
  }
  @media ${BREAKPOINTS.mobile} {
    white-space: normal;
    margin-bottom: 6.25vw;
  }
`;

const CImage = styled.img`
  position: absolute;
  top: -290px;
  right: -70px;
  width: 1000px;
  height: auto;
  z-index: 1;
  @media ${BREAKPOINTS.laptop} {
    top: -20.714vw;
    right: -12.857vw;
    width: 71.429vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const ConceptionTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin-top: 50px;

  @media ${BREAKPOINTS.laptop} {
    flex-direction: row;
  }

  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
  }
`;

const CP = styled(P)`
  width: 48%;
  color: ${COLORS.font.white};

  @media ${BREAKPOINTS.mobile} {
    width: 100%;
    margin-bottom: 6.25vw;
  }
`;

export default Conception;
