import React, { useCallback, useEffect, useRef, useState } from 'react';
import img5 from '../../../assets/winners/1.png';
import img4 from '../../../assets/winners/2.png';
import img3 from '../../../assets/winners/3.png';
import img2 from '../../../assets/winners/4.png';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../styles/variables';

const images = [ img2, img3, img4, img5];

const Layer = React.memo(({ src, index, offset, scrollOffset }) => {
  const movement = 40 - index * 20;
  const scrollMovement = scrollOffset * ((index - 10) / 2);
  return (
    <ParallaxLayer
      src={src}
      alt={`Layer ${index + 1}`}
      style={{
        transform: `translate(${offset.x * movement }px, ${offset.y * movement + (scrollMovement+900)}px)`,
      }}
    />
  );
});

const ParallaxWinners = () => {

  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [scrollOffset, setScrollOffset] = useState(0);
  const requestRef = useRef(null);
  const isMouseMoveEnabled = useRef(true);

  const handleMouseMove = useCallback((e) => {
    if (!isMouseMoveEnabled.current) return;
    const { clientX, clientY } = e;
    const x = (clientX - window.innerWidth / 2) / window.innerWidth;
    const y = (clientY - window.innerHeight / 2) / window.innerHeight;

    if (requestRef.current) cancelAnimationFrame(requestRef.current);
    requestRef.current = requestAnimationFrame(() => {
      setOffset({ x, y });
    });
  }, []);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY || window.pageYOffset;
    const scrollAmount = scrollPosition / window.innerHeight;

    setScrollOffset(scrollAmount * 50);
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 480) {
      isMouseMoveEnabled.current = false;
    } else {
      isMouseMoveEnabled.current = true;
    }
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);

      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
  }, [handleMouseMove, handleScroll]);

  return (
    <ParallaxContainer>
      {images.map((image, index) => (
        <Layer key={index} src={image} index={index} offset={offset} scrollOffset={scrollOffset}/>
      ))}
    </ParallaxContainer>
  );
};



const ParallaxContainer = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 600px; 
  overflow: visible;
  @media ${BREAKPOINTS.laptop} {
    height: 42.86vw;
  }

  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const ParallaxLayer = styled.img`
  position: absolute;
  z-index: 1;
  width: 741px;
  height: 986px;
  object-fit: contain;
  left: -10%;
  bottom: -25%;
  pointer-events: none;
  transition: transform 0.1s ease-out;
  @media ${BREAKPOINTS.laptop} {
    width: 52.93vw;
    height: 70.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    bottom: 20vw;
    right: -25vw;
    width: 138.75vw;
    height: auto;
    will-change: transform;
  }
`;

export default ParallaxWinners;