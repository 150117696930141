import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../../styles/variables';
import SvgSelector from '../../Shared/SvgSelector';
import { ContentWr, H1 } from '../../../styles/textTags';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Parallax from './Parallax';

const Index = () => {
  useEffect(() => {
    AOS.init({
      once: false,
      duration: 700,
      easing: 'ease-in-out-quad',
      delay: 100,
    });
  }, []);

  return (
    <PreviewWr id="preview">
      <PreviewContentWr>
        <Parallax/>
        <SvgSelectorContainer data-aos="fade-right">
          <SvgSelector svg="previewLogo" />
          <H1>Точки сближения</H1>
        </SvgSelectorContainer>
        <FirstGradient />
        <SecondGradient />
      </PreviewContentWr>
    </PreviewWr>

  );
};

const PreviewWr = styled.section`
  scroll-padding-top: 300px;
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 100px;
  overflow: visible;
  background-color: ${COLORS.background.dark};
  @media ${BREAKPOINTS.laptop} {
    padding-top: 7.143vw;
    height: 57.143vw;
  }

  @media ${BREAKPOINTS.mobile} {
    padding-top: 0vw;
    height: 180vw;
  }
`;

const PreviewContentWr = styled(ContentWr)`
  position: relative;
  overflow: visible;
  @media ${BREAKPOINTS.mobile} {
    margin-right: 0;
    margin-left: 6.25vw;
    max-width: 437.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const FirstGradient = styled.div`
  position: absolute;
  top: -270px;
  left: 0px;
  width: 680px;
  height: 370px;
  transform: rotate(-20.51deg);
  border-radius: 50%;
  opacity: 0.5;
  background: linear-gradient(81.95deg, #fe85bf 7.42%, #fc5049 110.39%);

  filter: blur(60px);
  -webkit-filter: blur(60px);
  z-index: 9;    

  @media ${BREAKPOINTS.laptop} {
    top: -19.286vw;
    left: 0vw;
    width: 48.571vw;
    height: 26.429vw;
  }

  @media ${BREAKPOINTS.mobile} {
    top: 10vw;
    left: 0vw;
    width: 35vw;
    height: 35vw;
  }
`;

const SecondGradient = styled.div`
  position: absolute;
  top: -250px;
  left: -380px;
  width: 542px;
  height: 542px;
  z-index: 8;
  opacity: 0.9;
  border-radius: 50%;
  background: linear-gradient(36.55deg, #1072e5 14.08%, #3686e4 66.12%);
  filter: blur(60px);
  -webkit-filter: blur(60px);

  @media ${BREAKPOINTS.laptop} {
    top: -17.857vw;
    left: -27.143vw;
    width: 38.71vw;
    height: 38.71vw;
  }

  @media ${BREAKPOINTS.mobile} {
    top: 30vw;
    left: -10vw;
    width: 30vw;
    height: 40vw;
  }
`;

const ParallaxProv = styled.div`
  overflow: visible !important;
  width: 100%;
  height: 700px !important;
  @media ${BREAKPOINTS.laptop} {
    height: 40vw !important;
  }
  @media ${BREAKPOINTS.mobile} {
    height: 20vw !important;
  }
`

const RunImage = styled.img`
  position: absolute;
  overflow: visible;
  bottom: 100px;
  right: -100px;
  width: 1020px;
  height: 556px;
  z-index: -100;
  pointer-events: none;
  @media ${BREAKPOINTS.laptop} {
    bottom: 7.14vw;
    right: -7.14vw;
    width: 72.86vw;
    height: 39.71vw;
  }

  @media ${BREAKPOINTS.mobile} {
    bottom: -60vw;    
    right: -25vw;
    width: 138.75vw;
    height: auto;
  }
`;

const SvgSelectorContainer = styled.div`
  position: absolute;
  width: 520px;
  top: 150px;
  left: 0;
  z-index: 10;

  svg {
    width: 100%;
    height: 100%;
  }

  @media ${BREAKPOINTS.laptop} {
    width: 37.143vw;
    top: 10.714vw;
  }

  @media ${BREAKPOINTS.mobile} {
    width: 87.5vw;
    top: 40.625vw;
  }
`;

export default Index;
