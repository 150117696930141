import React from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../styles/variables";
import Org from "../../assets/organizator/o1.png";
import Org1 from "../../assets/organizator/o2.png";
import { H2 } from "../../styles/textTags";

import "aos/dist/aos.css";

const Organizator = () => {
  return (
    <ConceptionWr>
      <ContentContainer>
        <ContentBox>
          <OH2 data-aos="zoom-in">Организатор</OH2>
          <Image
            src={Org}
            alt="Агенство добровольной помощи"
            data-aos="flip-up"
          />
        </ContentBox>
        <ContentBox>
          <OH2 data-aos="zoom-in">
            Генеральный <br /> партнер проекта
          </OH2>
          <Image1
            src={Org1}
            alt="PublicArt"
            data-aos="flip-up"
          />
        </ContentBox>
      </ContentContainer>
    </ConceptionWr>
  );
};

const ConceptionWr = styled.section`
  width: 100%;
  padding: 50px 0;
  background-color: ${COLORS.background.dark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  margin: 80px auto 60px auto;
  gap: 130px;
  @media ${BREAKPOINTS.laptop} {
    margin: 5.714vw auto 4.286vw auto;
    gap: 9.286vw;
  }
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 10.938vw;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 20px;
  @media ${BREAKPOINTS.laptop} {
    margin: 0 1.429vw;
  }
  @media ${BREAKPOINTS.mobile} {
    margin: 0 10.938vw;
  }
`;

const OH2 = styled(H2)`
  color: ${COLORS.font.white};
  padding: 0;
  font-size: 24px;
  width: fit-content;

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.714vw;
  }

  @media ${BREAKPOINTS.mobile} {
    font-size: 7.5vw;
  }
`;

const Image = styled.img`
  width: 190px;
  height: auto;
  margin-top: 50px;
  pointer-events: none;
  user-select: none;
  @media ${BREAKPOINTS.laptop} {
    width: 13.571vw;
    margin-top: 3.571vw;
  }
  @media ${BREAKPOINTS.mobile} {
    width: 59.375vw;
    margin-top: 9.375vw;
  }
`;
const Image1 = styled.img`
  width: 500px;
  height: auto;
  margin-top: 70px;
  pointer-events: none;
  user-select: none;
  @media ${BREAKPOINTS.laptop} {
    width: 35.714vw;
    margin-top: 5vw;
  }
  @media ${BREAKPOINTS.mobile} {
    width: 78.125vw;
    margin-top: 9.375vw;
  }
`;
export default Organizator;
