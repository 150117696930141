import React from "react";
import { styled } from "styled-components";
import { ContentWr, H2 } from "../../styles/textTags";
import { BREAKPOINTS } from "../../styles/variables";
import video from "../../assets/video/video.mp4";

import "aos/dist/aos.css";

const VideoSection = () => {
  return (
    <RefereesWr>
      <RefereesContentWr>
        <RefereesH2
          $green
          data-aos="zoom-in"
        >
          как это было
        </RefereesH2>
        <Video
          data-aos="zoom-in-up"
          src={video}
          controls
        />
      </RefereesContentWr>
    </RefereesWr>
  );
};

const RefereesWr = styled.section`
  padding: 100px 0;
  @media ${BREAKPOINTS.laptop} {
    padding: 7.14vw 0;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 12.5vw 0;
  }
`;

const RefereesContentWr = styled(ContentWr)``;

const RefereesH2 = styled(H2)`
  margin-bottom: 50px;
  @media ${BREAKPOINTS.laptop} {
    margin-bottom: 3.57vw;
  }
  @media ${BREAKPOINTS.mobile} {
    margin-bottom: 10vw;
  }
`;

const Video = styled.video`
  width: 1200px;
  height: 592px;
  border-radius: 24px;
  object-fit: cover;

  @media ${BREAKPOINTS.laptop} {
    width: 85.71vw;
    height: 42.29vw;
    border-radius: 1.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    width: 100%;
    height: 57.19vw;
  }
`;

export default VideoSection;
