import React from "react";
import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import { BREAKPOINTS, COLORS } from "../../../../styles/variables";

const ResultsSlider = ({ images }) => {
  return (
    <SliderBlock>
      <SwiperStyled
        slidesPerView={"auto"}
        spaceBetween={30}
        speed={1000}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {images.map((image, index) => (
          <SwiperSlideStyled key={index}>
            <SlideImage
              src={image}
              alt={`Изображение ${index}`}
            />
          </SwiperSlideStyled>
        ))}
      </SwiperStyled>
    </SliderBlock>
  );
};

const SliderBlock = styled.div`
  margin-bottom: 70px;
  position: relative;
  @media ${BREAKPOINTS.laptop} {
    margin-bottom: 6vw;
  }
`;

const SwiperStyled = styled(Swiper)`
  height: 580px;
  max-width: 1200px;
  margin: 0 auto;
  overflow: visible !important;

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: ${COLORS.background.green};
    opacity: 1;
    margin: 0 6px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: ${COLORS.background.blurRed};
  }
  @media ${BREAKPOINTS.laptop} {
    height: 41.43vw;
    max-width: 85.71vw;
    .swiper-pagination-bullet {
      width: 1.14vw;
      height: 1.14vw;
      margin: 0 0.43vw !important;
    }
  }

  @media ${BREAKPOINTS.mobile} {
    height: 70vw;
    max-width: 85.71vw;
    .swiper-pagination-bullet {
      width: 5vw;
      height: 5vw;
      margin: 0 2.19vw !important;
    }
  }
`;

const SwiperSlideStyled = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 781px !important;
  height: 475px;
  z-index: 199;
  @media ${BREAKPOINTS.laptop} {
    width: 55.79vw !important;
    height: 33.93vw;
  }
  @media ${BREAKPOINTS.mobile} {
    width: 70.31vw !important;
    height: 55.94vw;
  }
`;

const SlideImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
`;

export default ResultsSlider;
