import React, { useEffect } from 'react';
import Header from './components/Shared/Header';
import Footer from './components/Shared/Footer';
import Preview from './components/Main/Preview';
import Goals from './components/Main/Goals';
import AboutUs from './components/Main/AboutUs';
import OpenCall from './components/Main/OpenCall';
import Winners from './components/Main/Winners';
import Conception from './components/Main/Conception';
import Referees from './components/Main/Referees';
// import Application from "./components/Main/Application";
// import Slider from "./components/Main/Slider/Slider";
import Partners from './components/Main/Partners/index';
import Organizator from './components/Main/Organizator';
import Aos from 'aos';
import Artists from './components/Main/Artists';
import VideoSection from './components/Main/VideoSection';
import Media from './components/Main/Media';
import Results from './components/Main/Results';
import Slider from './components/Main/Slider/Slider';
import Application from './components/Main/Application';

function App() {
  useEffect(() => {
    Aos.init({
      once: false,
      duration: 350,
      easing: 'ease-in-out-quad',
      delay: 50,
    });
  }, []);

  return (
    <>
      <Header />
      <main>
        <Preview />
        <AboutUs />
        <Goals />
        <OpenCall />
        <Winners />
        <Artists />
        {/*<Application />*/}
        <Conception />
        <Referees />
        {/*<Results />*/}
        <Slider />
        {/*<VideoSection />*/}
        <Organizator />
        <Partners />
        {/*<Media />*/}
      </main>
      <Footer />
    </>
  );
}

export default App;
