import React from "react";
import PartnersSlider from "./PartnersSlider";
import { INFOPARTNERS_DATA, PARTNERS_DATA } from "../../../features/data";
import { styled } from "styled-components";
import { BREAKPOINTS } from "../../../styles/variables";

const Partners = () => {
  return (
    <>
      <PartnersSlider
        data={PARTNERS_DATA}
        partnersHeading="Партнеры"
        paginationClass={"swiper-pagination-partners"}
      />
      <PartnersSlider
        data={INFOPARTNERS_DATA}
        partnersHeading={<Heading />}
        paginationClass="swiper-pagination-infopartners"
      />
    </>
  );
};

const Heading = styled.span`
  &:before {
    content: "Инфопартнеры";
  }

  @media ${BREAKPOINTS.mobile} {
    content: none;
    &:before {
      content: "Инфо партнеры";
    }
  }
`;

export default Partners;
