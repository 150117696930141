import React from "react";
import { ContentWr, H2, P } from "../../styles/textTags";
import { GOALS_DATA } from "../../features/data";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../styles/variables";
import img from "../../assets/goals/1.png";

import "aos/dist/aos.css";

const Goals = () => {
  return (
    <Section>
      <ContentWr>
        <H2
          $green
          data-aos="zoom-in"
        >
          цели проекта
        </H2>
        <BlocksWr>
          <Block1 data-aos="fade-down">
            <h4>{GOALS_DATA[0].number}</h4>
            <P>{GOALS_DATA[0].text}</P>
          </Block1>
          <Block2 data-aos="fade-down">
            <h4>{GOALS_DATA[1].number}</h4>
            <P>{GOALS_DATA[1].text}</P>
          </Block2>
          <Block3 data-aos="fade-down">
            <h4>{GOALS_DATA[2].number}</h4>
            <P>{GOALS_DATA[2].text}</P>
          </Block3>
        </BlocksWr>
      </ContentWr>
    </Section>
  );
};

const Section = styled.section`
  padding: 100px 0;
  @media ${BREAKPOINTS.laptop} {
    padding: 7.14vw 0;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 12.5vw 0;
  }
`;

const BlocksWr = styled.div`
  display: flex;
  height: 640px;
  gap: 80px;
  margin-top: 50px;
  position: relative;
  &:before {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: -35%;
    width: 900px;
    height: 900px;
    background-image: url(${img});
    background-size: cover;
    z-index: -1;
  }
  @media ${BREAKPOINTS.laptop} {
    height: 45.71vw;
    gap: 5.71vw;
    margin-top: 3.57vw;
    &:before {
      width: 64.29vw;
      height: 64.29vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    height: auto;
    gap: 3.75vw;
    margin-top: 10vw;
    &:before {
      right: -105%;
      bottom: -10%;
      width: 244vw;
      height: 244vw;
    }
  }
`;

const Block = styled.div`
  display: inline-flex;
  width: 100%;
  height: fit-content;
  padding: 30px 14px 30px 35px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 24px;
  background-color: ${COLORS.background.white};

  h4 {
    font-family: Unbounded;
    font-size: 96px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  @media ${BREAKPOINTS.laptop} {
    padding: 2.14vw 1vw 2.14vw 2.5vw;
    border-radius: 1.71vw;
    h4 {
      font-size: 6.86vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 12.5vw 6.25vw;
    border-radius: 7.5vw;
    h4 {
      font-size: 21.88vw;
    }
  }
`;
const Block1 = styled(Block)`
  margin-bottom: auto;
`;

const Block2 = styled(Block)`
  margin: auto 0;
`;

const Block3 = styled(Block)`
  margin-top: auto;
`;

export default Goals;
