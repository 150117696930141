import React from "react";
import { styled } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Grid, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import "aos/dist/aos.css";
import { BREAKPOINTS, COLORS } from "../../../styles/variables";
import { H2 } from "../../../styles/textTags";

const PartnersSlider = ({ data, partnersHeading, paginationClass }) => {
  return (
    <PartnersWr>
      <PartnersContentWr>
        <PartnersH2 data-aos="zoom-in">{partnersHeading}</PartnersH2>
        <Gradient1 />
        <SwiperWrapper>
          <SwiperStyled
            slidesPerView={4}
            spaceBetween={28}
            speed={1000}
            freeMode={true}
            pagination={{
              clickable: true,
              el: `.${paginationClass}`,
            }}
            modules={[FreeMode, Pagination, Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            className="mySwiper"
          >
            {data.map((partner, index) => (
              <SwiperSlideStyled key={index}>
                <PartnerImage
                  src={partner.image}
                  alt={partner.alt}
                />
              </SwiperSlideStyled>
            ))}
          </SwiperStyled>
          <CustomPagination className={paginationClass} />
        </SwiperWrapper>
        <Gradient2 />
        <SwiperMobileWrapper>
          <SwiperMobileStyled
            slidesPerView={1}
            spaceBetween={20}
            grid={{
              rows: 4,
              fill: "column",
            }}
            pagination={{
              clickable: true,
              el: `.${paginationClass}-mobile`,
            }}
            slidesPerGroup={1}
            modules={[FreeMode, Grid, Pagination]}
            className="mySwiper"
          >
            {data.map((partner, index) => (
              <SwiperSlideStyled key={index}>
                <PartnerImage
                  src={partner.image}
                  alt={partner.alt}
                />
              </SwiperSlideStyled>
            ))}
          </SwiperMobileStyled>
          <CustomPagination className={`${paginationClass}-mobile`} />
        </SwiperMobileWrapper>
      </PartnersContentWr>
    </PartnersWr>
  );
};

const PartnersWr = styled.section`
  padding: 83px 0 120px;
  background-color: ${COLORS.background.white};
  @media ${BREAKPOINTS.laptop} {
    padding: 5.93vw 0 8.57vw;
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 2.5vw 0 4vw 0;
  }
`;

const PartnersContentWr = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  @media ${BREAKPOINTS.laptop} {
    max-width: 85.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    max-width: 100%;
    padding: 0 5vw;
  }
`;

const PartnersH2 = styled(H2)`
  width: 427px;
  display: flex;
  justify-content: center;

  @media ${BREAKPOINTS.laptop} {
    width: 30.5vw;
  }
  @media ${BREAKPOINTS.mobile} {
    margin: 0 auto;
    width: 70.94vw;
    margin-top: 27.19vw;
    margin-bottom: 5vw;
  }
`;

const SwiperWrapper = styled.div`
  position: absolute;
  left: 470px;
  top: -40px;
  width: 730px;
  @media ${BREAKPOINTS.laptop} {
    left: 33.57vw;
    top: -2.2vw;
    width: 52.14vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;
const Gradient1 = styled.div`
  position: absolute;
  width: 10px;
  height: 150px;

  background: -webkit-linear-gradient(90deg, ${COLORS.background.white} 14.67%, rgba(249, 249, 249, 0) 100%);
  background: linear-gradient(90deg, ${COLORS.background.white} 14.67%, rgba(249, 249, 249, 0) 100%);

  top: 50%;
  transform: translateY(-50%);
  left: 470px;
  z-index: 2;
  @media ${BREAKPOINTS.laptop} {
    width: 0.71vw;
    height: 10.71vw;
    left: 33.57vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;
const Gradient2 = styled.div`
  position: absolute;
  width: 10px;
  height: 150px;
  background: -webkit-linear-gradient(270deg, ${COLORS.background.white} 14.78%, rgba(249, 249, 249, 0) 100%);
  background: linear-gradient(270deg, ${COLORS.background.white} 14.78%, rgba(249, 249, 249, 0) 100%);
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 2;
  @media ${BREAKPOINTS.laptop} {
    width: 0.71vw;
    height: 10.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const SwiperMobileWrapper = styled.div`
  display: none;
  @media ${BREAKPOINTS.mobile} {
    display: block;
    position: relative;
    margin-bottom: 22vw;
  }
`;

const SwiperStyled = styled(Swiper)`
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const SwiperMobileStyled = styled(Swiper)`
  display: none;
  @media ${BREAKPOINTS.mobile} {
    display: block;
    height: 210vw;
  }
`;

const CustomPagination = styled.div`
  position: absolute;
  left: -562px !important;
  top: 150px !important;
  display: flex;
  justify-content: start;
  margin-top: 20px;
  padding-left: 100px;
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: ${COLORS.background.green};
    opacity: 1;
    margin: 0 6px !important;
  }

  .swiper-pagination-bullet-active {
    background-color: ${COLORS.background.dark};
  }

  @media ${BREAKPOINTS.laptop} {
    left: -40.14vw !important;
    top: 10vw !important;
    display: flex;

    margin-top: 1.43vw;
    padding-left: 7.14vw;
    .swiper-pagination-bullet {
      width: 1.14vw;
      height: 1.14vw;

      margin: 0 0.43vw !important;
    }
  }

  @media ${BREAKPOINTS.mobile} {
    justify-content: center;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: -12.5vw !important;
    top: auto !important;

    margin-top: 6.25vw;
    padding-left: 0;
    .swiper-pagination-bullet {
      width: 5vw;
      height: 5vw;
      margin: 0 1.88vw !important;
    }
  }
`;

const SwiperSlideStyled = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PartnerImage = styled.img`
  height: 150px;
  user-select: none;
  pointer-events: none;
  @media ${BREAKPOINTS.laptop} {
    height: 9vw;
  }
  @media ${BREAKPOINTS.mobile} {
    height: 46.875vw;
  }
`;

export default PartnersSlider;
