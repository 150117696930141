const LIGHT_COLOR = '#F9F9F9';
const DARK_COLOR = '#080808';
const BLUE_COLOR = '#155FCC';
const GREEN_COLOR = '#CBEC6E';
const GRAY_COLOR = '#696969';
const RED_COLOR = '#ED3629';
const DARK_RED_COLOR = '#A82A21';
const ORANGE_COLOR = '#FC6C03';
const DARK_GREEN_COLOR = '#82954B';

const BLUR_RED = '#ff4747';
const BLUR_ORANGE = '#FC9967';

const BLUR_GRAY = '#C4C4C4';

const WHITE_COLOR = '#FFFFFF';
const BLACK_COLOR = '#000000';

export const COLORS = {
  background: {
    light: LIGHT_COLOR,
    white: WHITE_COLOR,
    dark: DARK_COLOR,
    blue: BLUE_COLOR,
    green: GREEN_COLOR,
    orange: ORANGE_COLOR,
    red: RED_COLOR,
    blurRed: BLUR_RED,
  },
  font: {
    dark: DARK_COLOR,
    white: WHITE_COLOR,
    gray: GRAY_COLOR,
    green: GREEN_COLOR,
    darkGreen: DARK_GREEN_COLOR,
    red: RED_COLOR,
  },
  other: {
    red: RED_COLOR,
    darlRed: DARK_RED_COLOR,
    black: BLACK_COLOR,
    white: WHITE_COLOR,
    blurRed: BLUR_RED,
    blurOrange: BLUR_ORANGE,
    blurGray: BLUR_GRAY,
  },
};

const breakpoints = {
  laptopBP: '1400px',
  mobileBP: '480px',
};

export const BREAKPOINTS = {
  laptop: `(max-width: ${breakpoints.laptopBP})`,
  mobile: `(max-width: ${breakpoints.mobileBP})`,
};
