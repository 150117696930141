import React from 'react';
import { styled } from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../../styles/variables';
import { P } from '../../../styles/textTags';

const ArtistCard = ({ title, paragraph }) => {
  return (
    <CardWr>
      <H4>{title}</H4>
      <CardP>{paragraph}</CardP>
    </CardWr>
  );
};

const CardWr = styled.div`
  padding: 36px;
  min-width: 387px;
  max-width: 584px;
  height: 210px;
  background-color: ${COLORS.background.white};
  border-radius: 24px;
  z-index: 2;
  @media ${BREAKPOINTS.laptop} {
    padding: 2.57vw;
    min-width: 27.64vw;
    max-width: 41.71vw;
    height: 15vw;
    border-radius: 1.71vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    height: 59.38vw;
    border-radius: 5vw;
    padding: 6.25vw;
  }
`;

const H4 = styled.h4`
  color: ${COLORS.font.dark};  
  font-family: Unbounded, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 30px;
  @media ${BREAKPOINTS.laptop} {
    font-size: 1.71vw;
    margin-bottom: 2.14vw;
  }
  @media ${BREAKPOINTS.mobile} {
    font-size: 6.25vw;
    margin-bottom: 5vw;
  }
`;

const CardP = styled(P)`
  color: ${COLORS.font.dark};
`;

export default ArtistCard;
