const SvgSelector = ({ svg }) => {
  switch (svg) {
    case "headerLogo":
      return (
        <svg
          width="86"
          height="36"
          viewBox="0 0 86 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_610_119)">
            <path
              d="M0 7.89075H15.5509V10.2894H0V7.89075ZM6.4121 9.15192H9.14182V23.4005H6.41057L6.4121 9.15192Z"
              fill="white"
            />
            <path
              d="M24.8758 23.689C23.111 23.689 21.5604 23.3516 20.224 22.6767C18.9386 22.0479 17.8565 21.0695 17.1019 19.8536C16.3569 18.6474 15.9844 17.2447 15.9844 15.6456C15.9844 14.0465 16.3569 12.6438 17.1019 11.4376C17.8561 10.2214 18.9383 9.2428 20.224 8.61448C21.5604 7.93859 23.111 7.60065 24.8758 7.60065C26.6407 7.60065 28.1918 7.93859 29.5292 8.61448C30.8143 9.24362 31.8963 10.222 32.6513 11.4376C33.3953 12.6438 33.7673 14.0465 33.7673 15.6456C33.7673 17.2447 33.3953 18.6474 32.6513 19.8536C31.8957 21.0687 30.8138 22.0469 29.5292 22.6767C28.1918 23.3516 26.6407 23.689 24.8758 23.689ZM24.8758 21.2461C26.1308 21.2461 27.2132 21.0186 28.1231 20.5636C29.0032 20.1353 29.7396 19.4599 30.2421 18.6199C30.7365 17.7054 30.9954 16.6822 30.9954 15.6426C30.9954 14.6029 30.7365 13.5797 30.2421 12.6652C29.7389 11.8257 29.0028 11.1505 28.1231 10.7215C27.2132 10.2665 26.1308 10.039 24.8758 10.039C23.6209 10.039 22.542 10.2665 21.6392 10.7215C20.7561 11.149 20.0169 11.8251 19.5126 12.6667C19.0182 13.5812 18.7593 14.6045 18.7593 15.6441C18.7593 16.6837 19.0182 17.7069 19.5126 18.6214C20.0174 19.4634 20.7578 20.1391 21.6423 20.5651C22.5583 21.0211 23.6361 21.2491 24.8758 21.2491V21.2461Z"
              fill="white"
            />
            <path
              d="M52.7104 23.689V8.17932H55.4402V21.3942L54.6127 20.8766L64.9942 8.17932H67.9514L55.1516 23.689H52.7104ZM60.092 15.893L62.0156 14.0195L68.1773 23.6967H65.0553L60.092 15.893Z"
              fill="white"
            />
            <path
              d="M69.9133 23.689V8.17932H72.5606V22.408L71.6293 22.2416L82.4444 8.17932H86.0016V23.689H83.3543V9.33666L84.2841 9.50158L73.3881 23.6875L69.9133 23.689Z"
              fill="white"
            />
            <path
              d="M8.4135 32.789C8.35203 33.3991 8.12326 33.9803 7.75244 34.4686C7.3695 34.9627 6.86654 35.3506 6.2914 35.5954C5.64203 35.8715 4.94199 36.0083 4.23647 35.997C3.46958 36.0128 2.70955 35.8497 2.01666 35.5206C1.40181 35.2271 0.884891 34.7621 0.52814 34.1816C0.182045 33.5759 0 32.8904 0 32.1928C0 31.4952 0.182045 30.8098 0.52814 30.2041C0.884891 29.6236 1.40181 29.1586 2.01666 28.8651C2.70955 28.536 3.46958 28.3729 4.23647 28.3887C4.94199 28.3774 5.64203 28.5142 6.2914 28.7902C6.86654 29.0351 7.3695 29.423 7.75244 29.9171C8.12305 30.4054 8.35179 30.9866 8.4135 31.5966H6.383C6.34234 31.3795 6.25924 31.1725 6.13847 30.9875C6.0177 30.8025 5.86163 30.6432 5.6792 30.5186C5.2468 30.2427 4.73968 30.1073 4.22731 30.1308C3.82334 30.1205 3.42264 30.2058 3.05787 30.3797C2.73812 30.5368 2.47238 30.7855 2.29452 31.0943C2.12089 31.4333 2.03033 31.8088 2.03033 32.1898C2.03033 32.5707 2.12089 32.9462 2.29452 33.2853C2.47238 33.594 2.73812 33.8428 3.05787 33.9999C3.47608 34.1833 3.9311 34.2673 4.38723 34.2453C4.84336 34.2233 5.2882 34.0959 5.68683 33.8731C5.87059 33.748 6.02704 33.5868 6.14671 33.3995C6.26637 33.2121 6.34676 33.0023 6.383 32.7829L8.4135 32.789Z"
              fill="white"
            />
            <path
              d="M14.1693 31.3217C14.6823 31.3056 15.1928 31.401 15.6654 31.6012C16.0393 31.7633 16.3532 32.0382 16.5631 32.3875C16.7671 32.7528 16.8694 33.1662 16.8593 33.5845C16.869 34.0031 16.7577 34.4156 16.5387 34.7724C16.3143 35.1207 15.9919 35.3949 15.612 35.5603C15.1569 35.7564 14.6647 35.8517 14.1693 35.8397H9.42432L9.98766 32.0547L9.42432 28.5521H16.2944V30.0789H11.5892L11.7937 31.3172L14.1693 31.3217ZM13.9067 34.3755C14.1576 34.3902 14.4052 34.3113 14.6013 34.1541C14.7637 33.9905 14.8549 33.7693 14.8549 33.5388C14.8549 33.3082 14.7637 33.087 14.6013 32.9234C14.4052 32.7647 14.157 32.6847 13.9051 32.699H11.8044L11.5464 34.3785L13.9067 34.3755Z"
              fill="white"
            />
            <path
              d="M26.4286 28.5521V35.8397H24.4133V30.3431H21.3187C21.3676 30.8897 21.392 31.4465 21.392 32.0134C21.4127 32.7849 21.3269 33.5556 21.1371 34.3037C20.9661 34.887 20.7014 35.316 20.3432 35.5908C19.9324 35.8813 19.4365 36.0264 18.934 36.0031C18.6834 36.0032 18.4335 35.9756 18.189 35.9206C17.9587 35.8709 17.7356 35.7924 17.5249 35.687V34.0166C17.775 34.12 18.0421 34.1759 18.3127 34.1815C18.4369 34.1875 18.5611 34.1683 18.6778 34.1253C18.7945 34.0823 18.9014 34.0163 18.992 33.9311C19.1905 33.7104 19.3218 33.4376 19.3707 33.1448C19.4617 32.6803 19.5027 32.2073 19.4928 31.734C19.479 30.675 19.4092 29.6174 19.2836 28.5658L26.4286 28.5521Z"
              fill="white"
            />
            <path
              d="M30.0622 33.8334L33.6316 28.5521H36.2636V35.8397H34.2789V30.4515L30.6362 35.8397H28.0698V28.5521H30.0622V33.8334Z"
              fill="white"
            />
            <path
              d="M48.222 31.5981L50.8173 35.8397H48.5273L46.9487 33.1571L44.7579 35.8397H43.2831L41.0877 33.1571L39.5122 35.8397H37.2222L39.8175 31.5981L37.326 28.5444H39.4527L43.0236 33.1051V28.5582H45.0083V33.1005L48.5838 28.5582H50.7105L48.222 31.5981Z"
              fill="white"
            />
            <path
              d="M53.9195 32.9295L53.715 34.2075H58.3301V35.8397H51.5547L52.1073 32.1966L51.5547 28.5521H58.2813V30.1843H53.7104L53.915 31.4622H57.9943V32.9295H53.9195Z"
              fill="white"
            />
            <path
              d="M65.5133 31.2485V28.5521H67.5285V35.8397H65.5133V33.0272H61.7882V35.8397H59.7729V28.5521H61.7882V31.2485H65.5133Z"
              fill="white"
            />
            <path
              d="M71.1622 33.8334L74.7331 28.5521H77.3651V35.8397H75.3804V30.4515L71.7255 35.8397H69.1714V28.5521H71.1561L71.1622 33.8334Z"
              fill="white"
            />
            <path
              d="M86.0014 28.5521V35.8397H83.9847V33.2166H82.8336L80.916 35.8397H78.5833L80.6626 33.0746C80.3049 32.9992 79.9656 32.8539 79.6642 32.6471C79.3875 32.4538 79.1622 32.1959 79.0077 31.8959C78.8398 31.5453 78.7627 31.158 78.7835 30.7698C78.8043 30.3817 78.9223 30.0049 79.1268 29.6743C79.3674 29.3115 79.707 29.0254 80.1054 28.8498C80.5624 28.6483 81.0579 28.5487 81.5573 28.5582L86.0014 28.5521ZM83.9847 31.792V29.9903H81.8473C81.5723 29.974 81.3009 30.0587 81.084 30.2285C80.9968 30.3156 80.9276 30.419 80.8804 30.5328C80.8332 30.6467 80.8089 30.7687 80.8089 30.8919C80.8089 31.0152 80.8332 31.1372 80.8804 31.2511C80.9276 31.3649 80.9968 31.4683 81.084 31.5554C81.3011 31.7246 81.5725 31.8088 81.8473 31.792H83.9847Z"
              fill="white"
            />
            <path
              d="M48.2373 19.4566C46.7106 18.7161 45.7412 17.7206 45.1442 16.6411C45.5884 16.3921 46.0099 16.1044 46.4037 15.7815C49.335 13.3676 52.4861 9.30614 51.7181 5.80203C51.4861 4.74698 50.6815 2.86743 47.9014 2.1376C45.1778 1.42151 42.3153 3.73161 40.7764 7.88616C40.1148 9.65636 39.7956 11.5362 39.8359 13.4256C39.1473 13.3915 38.4695 13.2407 37.8314 12.9797C36.7627 12.5354 35.4513 11.5842 35.2604 9.57334C34.9902 6.7166 36.8085 4.90118 37.4207 4.38206C37.4207 4.38206 37.4573 4.35457 37.5153 4.30418L37.6146 4.22631L37.5993 4.23852C38.1138 3.7652 39.5168 2.23684 38.1978 0.648922C37.0344 -0.748143 35.0375 0.600052 35.0375 0.600052C34.8436 0.737468 30.2636 4.03393 30.83 10.007C31.1353 13.2485 33.0681 15.8365 36.1261 17.1068C37.6037 17.7155 39.1993 17.9837 40.7947 17.8916C41.7718 20.1498 43.5427 22.1393 46.2999 23.4799C46.8314 23.7382 47.4436 23.7748 48.0021 23.5817C48.5605 23.3886 49.0194 22.9815 49.2777 22.45C49.5361 21.9185 49.5727 21.3062 49.3796 20.7476C49.1865 20.1891 48.7794 19.7302 48.248 19.4719L48.2373 19.4566ZM44.3977 11.6178C44.8007 8.758 46.2541 6.80517 46.9121 6.48758C47.1136 6.55324 47.3472 6.65399 47.3701 6.75782C47.532 7.51819 46.5564 9.57944 44.3977 11.6178Z"
              fill="#155FCC"
            />
            <mask
              id="mask0_610_119"
              maskUnits="userSpaceOnUse"
              x="30"
              y="0"
              width="22"
              height="24"
            >
              <path
                d="M48.2373 19.4566C46.7106 18.7161 45.7412 17.7206 45.1442 16.6411C45.5884 16.3921 46.0099 16.1044 46.4037 15.7815C49.335 13.3676 52.4861 9.30615 51.7182 5.80203C51.4861 4.74698 50.6815 2.86743 47.9014 2.1376C45.1778 1.42151 42.3153 3.73161 40.7764 7.88616C40.1148 9.65636 39.7956 11.5362 39.8359 13.4256C39.1473 13.3915 38.4695 13.2407 37.8314 12.9797C36.7627 12.5354 35.4513 11.5842 35.2604 9.57334C34.9902 6.7166 36.8085 4.90118 37.4207 4.38206C37.4207 4.38206 37.4573 4.35457 37.5153 4.30418L37.6146 4.22631L37.5993 4.23852C38.1138 3.7652 39.5168 2.23684 38.1978 0.648922C37.0344 -0.748143 35.0375 0.600052 35.0375 0.600052C34.8436 0.737468 30.2636 4.03393 30.83 10.007C31.1353 13.2485 33.0681 15.8365 36.1261 17.1068C37.6037 17.7155 39.1993 17.9837 40.7947 17.8916C41.7718 20.1498 43.4404 22.3698 46.2999 23.4799C46.607 23.6153 46.937 23.6915 47.2724 23.7043C47.7762 23.7038 48.2649 23.5326 48.6589 23.2187C49.0529 22.9049 49.329 22.4668 49.4421 21.9759C49.5553 21.4849 49.4988 20.9702 49.282 20.5155C49.0651 20.0607 48.7006 19.6929 48.248 19.4719L48.2373 19.4566ZM44.3977 11.6178C44.8007 8.758 46.2541 6.80517 46.9121 6.48758C47.1136 6.55324 47.3472 6.65399 47.3701 6.75782C47.532 7.51819 46.5564 9.57944 44.3977 11.6178Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_610_119)">
              <path
                d="M39.6191 11.8667L39.8298 13.4225L40.784 17.8764C41.1293 18.716 41.5959 19.5005 42.1687 20.2048L46.822 18.11L45.1427 16.6412L44.3915 11.6178L45.1152 9.90471L39.3855 9.46039L39.6191 11.8667Z"
                fill="url(#paint0_linear_610_119)"
              />
            </g>
            <path
              d="M44.3978 11.6178C44.3978 11.6178 43.0512 13.221 39.8345 13.4225C36.1888 13.6516 40.1154 17.6763 40.1154 17.6763L40.7887 17.8763C42.3236 17.843 43.8245 17.4178 45.1489 16.6411C47.2558 15.3616 44.3978 11.6178 44.3978 11.6178Z"
              fill="#155FCC"
            />
            <path
              d="M51.6038 21.7866C51.6031 21.2601 51.4154 20.7509 51.0743 20.3499C50.7332 19.9489 50.2607 19.6821 49.7412 19.5971L49.6267 19.5803H49.5931H49.5565C48.1824 19.5146 47.6359 19.1421 47.6359 19.1421C47.6359 19.1421 45.4985 23.092 46.1428 23.4004C47.3641 23.9822 48.729 24.0112 49.2664 23.9959H49.3336C49.3595 23.9959 49.3855 23.9959 49.4114 23.9959H49.5015C50.6435 23.8463 51.6038 22.9546 51.6038 21.7866Z"
              fill="#155FCC"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_610_119"
              x1="42.2221"
              y1="9.48482"
              x2="43.9247"
              y2="19.1695"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                offset="0.14"
                stopColor="#155FCC"
              />
              <stop
                offset="0.21"
                stopColor="#144A9E"
              />
              <stop
                offset="0.29"
                stopColor="#123979"
              />
              <stop
                offset="0.33"
                stopColor="#12336B"
              />
              <stop
                offset="0.75"
                stopColor="#12336B"
              />
              <stop
                offset="0.97"
                stopColor="#155FCC"
              />
            </linearGradient>
            <clipPath id="clip0_610_119">
              <rect
                width="86"
                height="36"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case "burger":
      return (
        <svg
          // viewBox="0 0 6.1vw 5vw"
          width="6.1vw"
          height="5vw"
        >
          <g
            id="_01_align_center"
            dataname="01 align center"
            fill={"white"}
          >
            <rect
              id={"first"}
              x="0.1vw"
              y="0.1vw"
              width="6vw"
              height="0.9vw"
              rx="1"
              ry="1"
            />
            <rect
              id={"second"}
              x="0.1vw"
              y="2vw"
              width="6vw"
              height="0.9vw"
              rx="1"
              ry="1"
            />
            <rect
              id={"third"}
              x="0.1vw"
              y="3.9vw"
              width="6vw"
              height="0.9vw"
              rx="1"
              ry="1"
            />
          </g>
        </svg>
      );
    case "vkLogo":
      return (
        <svg
          width="50"
          height="31"
          viewBox="0 0 50 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.2 19.9221C34.4781 20.5421 39.225 25.1038 41.1297 31H50C47.5641 22.1867 41.1609 17.3151 37.1641 15.4535C41.1625 13.1564 46.7844 7.57175 48.1266 0H40.0687C38.3203 6.1442 33.1359 11.7304 28.2016 12.2574V0H20.1438V21.4737C15.1469 20.2321 8.83906 14.2119 8.55781 0H0C0.40625 19.3626 10.15 31 27.2312 31H28.2V19.9221Z"
            fill="white"
          />
        </svg>
      );
    case "tgLogo":
      return (
        <svg
          width="39"
          height="37"
          viewBox="0 0 39 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector"
            d="M2.62793 15.9056C14.6288 9.88866 30.159 2.5527 32.3026 1.53736C37.9259 -1.12017 39.6513 -0.610861 38.791 5.27445C38.1732 9.5045 36.3905 23.5094 34.9696 32.2263C34.1265 37.3955 32.2348 38.0082 29.2604 35.7717C27.83 34.6955 20.6102 29.2542 19.0432 27.9766C17.6128 26.8122 15.6401 25.4116 18.1142 22.6528C18.9945 21.6702 24.7658 15.3899 29.2622 10.5014C29.8514 9.85927 29.1115 8.80476 28.4315 9.3195C22.3709 13.9 13.9681 20.2576 12.8986 21.0857C11.2829 22.3362 9.73125 22.9097 6.94587 21.9978C4.84133 21.3089 2.78547 20.4872 1.98529 20.1738C-1.0961 18.968 -0.364663 17.4064 2.62793 15.9056Z"
            fill="white"
          />
        </svg>
      );
    case "footerLogo":
      return (
        <svg
          width="184"
          height="77"
          viewBox="0 0 184 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="&#195;&#144;&#194;&#161;&#195;&#144;&#194;&#187;&#195;&#144;&#194;&#190;&#195;&#144;&#194;&#185;_2"
            clipPath="url(#clip0_587_300)"
          >
            <path
              id="Vector"
              d="M0 16.8774H33.2716V22.0079H0V16.8774ZM13.7189 19.575H19.5592V50.051H13.7156L13.7189 19.575Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M53.2228 50.6681C49.4468 50.6681 46.1292 49.9464 43.27 48.5029C40.5199 47.158 38.2048 45.0651 36.5902 42.4645C34.9962 39.8846 34.1992 36.8845 34.1992 33.4641C34.1992 30.0438 34.9962 27.0436 36.5902 24.4637C38.2038 21.8623 40.5192 19.7692 43.27 18.4253C46.1292 16.9797 49.4468 16.2568 53.2228 16.2568C56.9987 16.2568 60.3174 16.9797 63.1788 18.4253C65.9284 19.771 68.2433 21.8637 69.8586 24.4637C71.4504 27.0436 72.2463 30.0438 72.2463 33.4641C72.2463 36.8845 71.4504 39.8846 69.8586 42.4645C68.242 45.0634 65.9274 47.1558 63.1788 48.5029C60.3174 49.9464 56.9987 50.6681 53.2228 50.6681ZM53.2228 45.4429C55.9078 45.4429 58.2236 44.9563 60.1704 43.9831C62.0535 43.0671 63.6289 41.6225 64.7042 39.8258C65.762 37.8698 66.3159 35.6812 66.3159 33.4576C66.3159 31.234 65.762 29.0454 64.7042 27.0894C63.6276 25.2938 62.0525 23.8495 60.1704 22.9321C58.2236 21.9589 55.9078 21.4723 53.2228 21.4723C50.5378 21.4723 48.2295 21.9589 46.298 22.9321C44.4084 23.8464 42.827 25.2924 41.7479 27.0926C40.6901 29.0486 40.1362 31.2372 40.1362 33.4609C40.1362 35.6845 40.6901 37.8731 41.7479 39.8291C42.8281 41.63 44.4121 43.0753 46.3045 43.9864C48.2644 44.9618 50.5704 45.4494 53.2228 45.4494V45.4429Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M112.776 50.6682V17.4946H118.616V45.7598L116.846 44.6527L139.057 17.4946H145.384L117.999 50.6682H112.776ZM128.569 33.9932L132.685 29.9861L145.868 50.6845H139.188L128.569 33.9932Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M149.582 50.6682V17.4946H155.246V47.9282L153.253 47.5722L176.392 17.4946H184.003V50.6682H178.339V19.9701L180.328 20.3228L157.016 50.6649L149.582 50.6682Z"
              fill="white"
            />
            <path
              id="Vector_5"
              d="M18.0012 70.1319C17.8697 71.4367 17.3802 72.6799 16.5869 73.7242C15.7676 74.7811 14.6915 75.6108 13.4609 76.1344C12.0716 76.7249 10.5738 77.0175 9.06433 76.9933C7.42354 77.0271 5.79743 76.6783 4.31497 75.9744C2.99946 75.3466 1.8935 74.352 1.13022 73.1103C0.389736 71.8149 0.000244141 70.3487 0.000244141 68.8566C0.000244141 67.3646 0.389736 65.8984 1.13022 64.603C1.8935 63.3614 2.99946 62.3668 4.31497 61.7389C5.79743 61.0351 7.42354 60.6862 9.06433 60.72C10.5738 60.6958 12.0716 60.9884 13.4609 61.5789C14.6915 62.1026 15.7676 62.9322 16.5869 63.989C17.3798 65.0336 17.8692 66.2767 18.0012 67.5814H13.6569C13.5699 67.1169 13.3921 66.6742 13.1337 66.2786C12.8753 65.8829 12.5414 65.5422 12.1511 65.2758C11.226 64.6856 10.141 64.3959 9.04473 64.4463C8.18041 64.4241 7.32309 64.6065 6.54266 64.9786C5.85854 65.3146 5.28998 65.8466 4.90945 66.5069C4.53795 67.2322 4.34421 68.0353 4.34421 68.8501C4.34421 69.6649 4.53795 70.4681 4.90945 71.1933C5.28998 71.8536 5.85854 72.3857 6.54266 72.7217C7.43744 73.114 8.41097 73.2937 9.38688 73.2467C10.3628 73.1996 11.3145 72.9272 12.1674 72.4506C12.5606 72.183 12.8953 71.8383 13.1513 71.4375C13.4074 71.0367 13.5794 70.588 13.6569 70.1188L18.0012 70.1319Z"
              fill="white"
            />
            <path
              id="Vector_6"
              d="M30.3156 66.9939C31.4133 66.9594 32.5054 67.1634 33.5166 67.5915C34.3166 67.9382 34.9881 68.5263 35.4373 69.2734C35.8738 70.0548 36.0926 70.9389 36.071 71.8337C36.0918 72.729 35.8536 73.6113 35.385 74.3745C34.9049 75.1195 34.2151 75.7059 33.4023 76.0597C32.4286 76.4792 31.3756 76.683 30.3156 76.6572H20.1636L21.3689 68.5615L20.1636 61.0698H34.8624V64.3356H24.7953L25.233 66.9841L30.3156 66.9939ZM29.7537 73.5254C30.2907 73.557 30.8203 73.3883 31.24 73.0519C31.5874 72.702 31.7824 72.2289 31.7824 71.7358C31.7824 71.2427 31.5874 70.7696 31.24 70.4197C30.8204 70.0802 30.2894 69.9091 29.7505 69.9396H25.2559L24.7039 73.532L29.7537 73.5254Z"
              fill="white"
            />
            <path
              id="Vector_7"
              d="M56.5448 61.0698V76.6572H52.2331V64.9005H45.6121C45.7167 66.0697 45.7689 67.2606 45.7689 68.4733C45.8131 70.1235 45.6296 71.7719 45.2234 73.3719C44.8576 74.6195 44.2914 75.5371 43.5249 76.125C42.6461 76.7462 41.5851 77.0565 40.51 77.0067C39.9738 77.0071 39.4392 76.9479 38.916 76.8303C38.4233 76.7239 37.9459 76.5561 37.4951 76.3307V72.7579C38.0302 72.9789 38.6018 73.0985 39.1806 73.1107C39.4464 73.1233 39.712 73.0824 39.9617 72.9904C40.2114 72.8984 40.4401 72.7571 40.6341 72.575C41.0587 72.1029 41.3397 71.5194 41.4442 70.8932C41.639 69.8996 41.7266 68.888 41.7055 67.8756C41.676 65.6105 41.5267 63.3485 41.258 61.0992L56.5448 61.0698Z"
              fill="white"
            />
            <path
              id="Vector_8"
              d="M64.3188 72.3661L71.9557 61.0698H77.587V76.6572H73.3406V65.1324L65.547 76.6572H60.0562V61.0698H64.3188V72.3661Z"
              fill="white"
            />
            <path
              id="Vector_9"
              d="M103.173 67.5847L108.726 76.657H103.826L100.448 70.9191L95.7612 76.657H92.6058L87.9087 70.9191L84.5378 76.657H79.6382L85.1911 67.5847L79.8603 61.0532H84.4104L92.0505 70.808V61.0826H96.2969V70.7982L103.947 61.0826H108.497L103.173 67.5847Z"
              fill="white"
            />
            <path
              id="Vector_10"
              d="M115.363 70.4328L114.925 73.1662H124.8V76.6572H110.303L111.486 68.8652L110.303 61.0698H124.695V64.5609H114.915L115.353 67.2943H124.081V70.4328H115.363Z"
              fill="white"
            />
            <path
              id="Vector_11"
              d="M140.168 66.8371V61.0698H144.48V76.6572H140.168V70.6417H132.198V76.6572H127.886V61.0698H132.198V66.8371H140.168Z"
              fill="white"
            />
            <path
              id="Vector_12"
              d="M152.254 72.3661L159.894 61.0698H165.525V76.6572H161.279V65.1324L153.459 76.6572H147.994V61.0698H152.241L152.254 72.3661Z"
              fill="white"
            />
            <path
              id="Vector_13"
              d="M184.003 61.0698V76.6572H179.688V71.0467H177.226L173.123 76.6572H168.132L172.581 70.743C171.815 70.5818 171.089 70.2711 170.444 69.8286C169.853 69.4152 169.37 68.8637 169.04 68.2218C168.681 67.4719 168.516 66.6437 168.56 65.8134C168.605 64.9831 168.857 64.1774 169.295 63.4701C169.81 62.6942 170.536 62.0822 171.388 61.7066C172.366 61.2756 173.426 61.0627 174.495 61.0829L184.003 61.0698ZM179.688 67.9997V64.1461H175.115C174.527 64.1112 173.946 64.2924 173.482 64.6556C173.296 64.8419 173.148 65.063 173.047 65.3065C172.946 65.55 172.894 65.811 172.894 66.0746C172.894 66.3382 172.946 66.5992 173.047 66.8427C173.148 67.0861 173.296 67.3073 173.482 67.4935C173.947 67.8556 174.528 68.0356 175.115 67.9997H179.688Z"
              fill="white"
            />
            <path
              id="Vector_14"
              d="M103.205 41.6155C99.939 40.0316 97.8649 37.9024 96.5877 35.5935C97.5381 35.0608 98.4399 34.4455 99.2825 33.7549C105.554 28.5917 112.296 19.9048 110.653 12.4099C110.156 10.1532 108.435 6.1331 102.487 4.57206C96.6596 3.04043 90.5351 7.98148 87.2425 16.8676C85.8271 20.6539 85.1443 24.6746 85.2304 28.7158C83.7571 28.6429 82.3069 28.3205 80.9416 27.7622C78.6551 26.8118 75.8493 24.7773 75.441 20.4763C74.8628 14.3661 78.7531 10.4831 80.0629 9.37271C80.0629 9.37271 80.1413 9.31392 80.2655 9.20615L80.4778 9.0396L80.4451 9.06571C81.5459 8.05332 84.5477 4.78434 81.7256 1.38795C79.2366 -1.60021 74.9641 1.28343 74.9641 1.28343C74.5493 1.57734 64.75 8.62811 65.9619 21.4037C66.6152 28.3369 70.7504 33.8724 77.293 36.5895C80.4544 37.8914 83.8684 38.4651 87.2817 38.2681C89.3722 43.0982 93.1613 47.3534 99.0604 50.2208C100.197 50.7734 101.507 50.8517 102.702 50.4386C103.897 50.0255 104.879 49.1548 105.432 48.018C105.984 46.8812 106.063 45.5715 105.649 44.3769C105.236 43.1823 104.365 42.2008 103.228 41.6482L103.205 41.6155ZM94.9904 24.8491C95.8528 18.7324 98.9624 14.5555 100.37 13.8762C100.801 14.0166 101.301 14.2321 101.35 14.4542C101.696 16.0806 99.6091 20.4893 94.9904 24.8491Z"
              fill="#155FCC"
            />
            <g id="Clip path group">
              <mask
                id="mask0_587_300"
                maskUnits="userSpaceOnUse"
                x="65"
                y="0"
                width="46"
                height="51"
              >
                <g id="clipPath">
                  <path
                    id="Vector_15"
                    d="M103.205 41.6155C99.9391 40.0316 97.8649 37.9024 96.5877 35.5935C97.5381 35.0608 98.4399 34.4455 99.2825 33.7549C105.554 28.5917 112.296 19.9048 110.653 12.4099C110.156 10.1532 108.435 6.1331 102.487 4.57207C96.6596 3.04043 90.5351 7.98148 87.2425 16.8676C85.8271 20.6539 85.1443 24.6746 85.2304 28.7158C83.7571 28.6429 82.3069 28.3205 80.9416 27.7622C78.6551 26.8118 75.8493 24.7773 75.441 20.4763C74.8628 14.3661 78.7531 10.4831 80.0629 9.37271C80.0629 9.37271 80.1413 9.31392 80.2655 9.20615L80.4778 9.0396L80.4451 9.06571C81.5459 8.05332 84.5477 4.78434 81.7256 1.38795C79.2366 -1.60021 74.9641 1.28343 74.9641 1.28343C74.5493 1.57734 64.75 8.62811 65.9619 21.4037C66.6152 28.3369 70.7504 33.8724 77.293 36.5895C80.4544 37.8914 83.8684 38.4651 87.2817 38.2681C89.3722 43.0982 92.9424 47.8466 99.0604 50.2208C99.7175 50.5105 100.423 50.6734 101.141 50.7009C102.219 50.6998 103.264 50.3337 104.107 49.6623C104.95 48.9909 105.541 48.0539 105.783 47.0039C106.025 45.9539 105.905 44.8528 105.441 43.8803C104.977 42.9077 104.197 42.1209 103.228 41.6482L103.205 41.6155ZM94.9904 24.8491C95.8528 18.7324 98.9624 14.5555 100.37 13.8762C100.801 14.0166 101.301 14.2321 101.35 14.4542C101.696 16.0806 99.6091 20.4893 94.9904 24.8491Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask0_587_300)">
                <g id="Group">
                  <path
                    id="Vector_16"
                    d="M84.7666 25.3817L85.2174 28.7095L87.2589 38.2357C87.9978 40.0316 88.996 41.7095 90.2215 43.216L100.178 38.7354L96.5845 35.5937L94.9774 24.8494L96.5257 21.1852L84.2668 20.2349L84.7666 25.3817Z"
                    fill="url(#paint0_linear_587_300)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Vector_17"
              d="M94.9905 24.8491C94.9905 24.8491 92.1095 28.2781 85.2272 28.7092C77.427 29.1991 85.8282 37.8076 85.8282 37.8076L87.2687 38.2355C90.5527 38.164 93.7639 37.2546 96.5975 35.5935C101.105 32.8568 94.9905 24.8491 94.9905 24.8491Z"
              fill="#155FCC"
            />
            <path
              id="Vector_18"
              d="M110.408 46.5991C110.406 45.473 110.005 44.384 109.275 43.5263C108.545 42.6686 107.534 42.0979 106.423 41.9161L106.178 41.8801H106.106H106.028C103.088 41.7397 101.918 40.9429 101.918 40.9429C101.918 40.9429 97.3454 49.3913 98.7238 50.051C101.337 51.2953 104.257 51.3573 105.407 51.3247H105.551C105.606 51.3247 105.662 51.3247 105.717 51.3247H105.91C108.353 51.0046 110.408 49.0974 110.408 46.5991Z"
              fill="#155FCC"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_587_300"
              x1="90.3358"
              y1="20.2871"
              x2="93.9765"
              y2="41.0021"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                offset="0.14"
                stopColor="#155FCC"
              />
              <stop
                offset="0.21"
                stopColor="#144A9E"
              />
              <stop
                offset="0.29"
                stopColor="#123979"
              />
              <stop
                offset="0.33"
                stopColor="#12336B"
              />
              <stop
                offset="0.75"
                stopColor="#12336B"
              />
              <stop
                offset="0.97"
                stopColor="#155FCC"
              />
            </linearGradient>
            <clipPath id="clip0_587_300">
              <rect
                width="184"
                height="77"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case "pushkeenLogo":
      return (
        <svg
          width="61"
          height="53"
          viewBox="0 0 61 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61 0H20.2C9.04003 0 0 9.1435 0 20.4313V45.6367V53L9.2 45.6367H40.8C51.96 45.6367 61 36.4931 61 25.2054V0ZM20.6803 9.30558C14.3604 9.30558 9.20036 14.5246 9.20036 20.917V36.372H13.8003V30.2224C15.7203 31.6789 18.1203 32.5689 20.6803 32.5689C27.0003 32.5689 32.1603 27.3499 32.1603 20.9575C32.1603 14.4842 27.0003 9.30558 20.6803 9.30558ZM20.6803 27.8758C16.8804 27.8758 13.8003 24.7605 13.8003 20.917C13.8003 17.0735 16.8804 13.9583 20.6803 13.9583C24.4803 13.9583 27.5604 17.0735 27.5604 20.917C27.5604 24.7605 24.4803 27.8758 20.6803 27.8758ZM44.0801 17.6398V19.1772C44.0801 19.9459 43.8801 21.7665 42.2001 22.697C44.0801 23.8299 44.0801 25.7314 44.0801 26.3787V31.3146C44.0801 31.9215 44.3601 32.3261 45.6401 32.3261H47.3201V36.21H43.6001C41.1601 36.21 39.6801 34.5513 39.6801 31.7192V26.9047C39.6801 24.8413 38.6401 24.8413 38.2801 24.8413H36.6001V20.7551H38.2801C38.6001 20.7551 39.6801 20.7551 39.6801 18.6513V17.5994V13.7559C39.6801 10.9238 41.1601 9.26497 43.6001 9.26497H47.3201V13.1489H45.6401C44.3201 13.1489 44.0801 13.5131 44.0801 14.1605V17.6398Z"
            fill="white"
          />
        </svg>
      );

    case "materials":
      return (
        <svg
          width="39"
          height="39"
          viewBox="0 0 39 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.25 0H15.8438C14.4976 0 13.4062 1.09139 13.4062 2.4375H26.8125V12.1875H36.5625V31.6875H28.0312V34.125H36.5625C37.9086 34.125 39 33.0336 39 31.6875V9.75061L29.25 0ZM29.25 9.75V3.44662L35.5522 9.75H29.25ZM2.4375 4.875C1.09139 4.875 0 5.96639 0 7.3125V36.5625C0 37.9086 1.09139 39 2.4375 39H23.1562C24.5024 39 25.5938 37.9086 25.5938 36.5625V14.6256L15.8438 4.875H2.4375ZM23.1562 36.5625H2.4375V7.3125H13.4062V17.0625H23.1562V36.5625ZM15.8438 14.625V8.32162L22.1459 14.625H15.8438Z"
            fill="#ED3629"
          />
        </svg>
      );
    case "previewLogo":
      return (
        <svg
          width="522"
          height="219"
          viewBox="0 0 522 219"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2060_135)">
            <path
              d="M0 48.6718H94.3901V63.197H0V48.6718ZM38.92 56.3089H55.4887V142.592H38.9107L38.92 56.3089Z"
              fill="white"
            />
            <path
              d="M150.991 144.339C140.279 144.339 130.867 142.296 122.755 138.209C114.954 134.401 108.386 128.476 103.805 121.113C99.283 113.809 97.022 105.315 97.022 95.6318C97.022 85.9482 99.283 77.4543 103.805 70.15C108.383 62.785 114.952 56.8592 122.755 53.0543C130.867 48.9615 140.279 46.915 150.991 46.915C161.703 46.915 171.118 48.9615 179.236 53.0543C187.036 56.8642 193.604 62.7889 198.186 70.15C202.702 77.4543 204.96 85.9482 204.96 95.6318C204.96 105.315 202.702 113.809 198.186 121.113C193.6 128.471 187.033 134.395 179.236 138.209C171.118 142.296 161.703 144.339 150.991 144.339ZM150.991 129.546C158.608 129.546 165.178 128.168 170.701 125.413C176.043 122.819 180.513 118.729 183.563 113.643C186.564 108.105 188.136 101.909 188.136 95.6133C188.136 89.3179 186.564 83.1216 183.563 77.5838C180.509 72.5002 176.041 68.4112 170.701 65.8137C165.178 63.0585 158.608 61.6808 150.991 61.6808C143.374 61.6808 136.825 63.0585 131.346 65.8137C125.985 68.4023 121.499 72.4962 118.437 77.593C115.436 83.1308 113.865 89.3271 113.865 95.6225C113.865 101.918 115.436 108.114 118.437 113.652C121.502 118.751 125.996 122.843 131.364 125.422C136.924 128.184 143.466 129.564 150.991 129.564V129.546Z"
              fill="white"
            />
            <path
              d="M319.941 144.339V50.4192H336.509V130.443L331.487 127.308L394.5 50.4192H412.45L334.758 144.339H319.941ZM364.745 97.1295L376.421 85.7848L413.821 144.385H394.871L364.745 97.1295Z"
              fill="white"
            />
            <path
              d="M424.357 144.339V50.4192H440.426V136.582L434.773 135.574L500.418 50.4192H522.009V144.339H505.941V57.4275L511.584 58.4262L445.448 144.33L424.357 144.339Z"
              fill="white"
            />
            <path
              d="M51.0685 199.445C50.6953 203.139 49.3068 206.659 47.056 209.615C44.7317 212.607 41.6788 214.956 38.1878 216.439C34.2463 218.111 29.9972 218.939 25.7149 218.87C21.06 218.966 16.4468 217.979 12.2412 215.986C8.50914 214.208 5.37157 211.392 3.20618 207.877C1.10546 204.21 0.000488281 200.058 0.000488281 195.834C0.000488281 191.61 1.10546 187.459 3.20618 183.792C5.37157 180.276 8.50914 177.46 12.2412 175.683C16.4468 173.69 21.06 172.702 25.7149 172.798C29.9972 172.73 34.2463 173.558 38.1878 175.23C41.6788 176.712 44.7317 179.061 47.056 182.053C49.3055 185.011 50.6939 188.53 51.0685 192.224H38.7438C38.497 190.909 37.9926 189.655 37.2596 188.535C36.5265 187.415 35.5792 186.45 34.4719 185.696C31.8474 184.025 28.7693 183.205 25.6593 183.348C23.2073 183.285 20.7751 183.802 18.561 184.855C16.6202 185.806 15.0073 187.312 13.9277 189.182C12.8738 191.235 12.3241 193.509 12.3241 195.816C12.3241 198.123 12.8738 200.396 13.9277 202.45C15.0073 204.319 16.6202 205.826 18.561 206.777C21.0995 207.888 23.8614 208.396 26.63 208.263C29.3986 208.13 32.0986 207.359 34.5182 206.009C35.6336 205.252 36.5832 204.276 37.3096 203.141C38.0359 202.006 38.5238 200.736 38.7438 199.408L51.0685 199.445Z"
              fill="white"
            />
            <path
              d="M86.0039 190.559C89.118 190.462 92.2162 191.039 95.0852 192.251C97.3546 193.233 99.2597 194.898 100.534 197.013C101.772 199.225 102.393 201.729 102.332 204.262C102.391 206.796 101.715 209.294 100.386 211.455C99.0237 213.564 97.0666 215.225 94.7609 216.226C91.9984 217.414 89.011 217.991 86.0039 217.918H57.2031L60.6225 194.998L57.2031 173.787H98.9031V183.033H70.3432L71.585 190.532L86.0039 190.559ZM84.41 209.051C85.9333 209.141 87.4357 208.663 88.6263 207.711C89.6121 206.72 90.1653 205.381 90.1653 203.985C90.1653 202.589 89.6121 201.249 88.6263 200.258C87.436 199.297 85.9296 198.813 84.4007 198.899H71.6498L70.0838 209.07L84.41 209.051Z"
              fill="white"
            />
            <path
              d="M160.415 173.787V217.918H148.183V184.633H129.4C129.696 187.943 129.844 191.315 129.844 194.748C129.97 199.42 129.449 204.087 128.297 208.617C127.259 212.149 125.653 214.747 123.478 216.411C120.985 218.17 117.975 219.048 114.925 218.907C113.404 218.908 111.887 218.741 110.403 218.408C109.005 218.107 107.651 217.632 106.372 216.993V206.878C107.89 207.504 109.512 207.843 111.154 207.877C111.908 207.913 112.661 207.797 113.37 207.537C114.078 207.276 114.727 206.876 115.277 206.361C116.482 205.024 117.279 203.372 117.575 201.599C118.128 198.786 118.377 195.922 118.317 193.056C118.233 186.643 117.809 180.239 117.047 173.871L160.415 173.787Z"
              fill="white"
            />
            <path
              d="M182.47 205.769L204.135 173.787H220.111V217.918H208.064V185.289L185.954 217.918H170.377V173.787H182.47V205.769Z"
              fill="white"
            />
            <path
              d="M292.697 192.233L308.45 217.918H294.55L284.968 201.673L271.671 217.918H262.719L249.394 201.673L239.83 217.918H225.93L241.684 192.233L226.561 173.741H239.469L261.144 201.359V173.824H273.19V201.331L294.893 173.824H307.801L292.697 192.233Z"
              fill="white"
            />
            <path
              d="M327.28 200.295L326.038 208.034H354.051V217.918H312.926L316.28 195.857L312.926 173.787H353.755V183.671H326.01L327.252 191.41H352.013V200.295H327.28Z"
              fill="white"
            />
            <path
              d="M397.651 190.116V173.787H409.883V217.918H397.651V200.887H375.04V217.918H362.808V173.787H375.04V190.116H397.651Z"
              fill="white"
            />
            <path
              d="M431.937 205.769L453.612 173.787H469.588V217.918H457.541V185.289L435.357 217.918H419.854V173.787H431.9L431.937 205.769Z"
              fill="white"
            />
            <path
              d="M522.009 173.787V217.918H509.768V202.034H502.781L491.142 217.918H476.983L489.604 201.174C487.433 200.717 485.373 199.838 483.543 198.585C481.864 197.414 480.496 195.853 479.559 194.036C478.54 191.913 478.072 189.568 478.198 187.217C478.324 184.867 479.041 182.585 480.282 180.583C481.742 178.386 483.804 176.654 486.222 175.59C488.996 174.37 492.003 173.767 495.034 173.824L522.009 173.787ZM509.768 193.407V182.497H496.795C495.126 182.398 493.478 182.911 492.161 183.939C491.632 184.467 491.212 185.093 490.926 185.782C490.639 186.471 490.492 187.21 490.492 187.957C490.492 188.703 490.639 189.442 490.926 190.131C491.212 190.821 491.632 191.447 492.161 191.974C493.479 192.999 495.127 193.509 496.795 193.407H509.768Z"
              fill="white"
            />
            <path
              d="M292.789 118.709C283.523 114.225 277.638 108.197 274.015 101.66C276.711 100.152 279.27 98.41 281.66 96.4546C299.452 81.8368 318.578 57.2427 313.917 36.0233C312.509 29.6344 307.625 18.2527 290.751 13.8332C274.219 9.49682 256.844 23.4858 247.503 48.6439C243.488 59.3635 241.55 70.7468 241.795 82.1881C237.615 81.9818 233.501 81.0688 229.628 79.4882C223.141 76.7977 215.181 71.0375 214.023 58.8607C212.383 41.5616 223.419 30.5682 227.135 27.4246C227.135 27.4246 227.357 27.2582 227.71 26.953L228.312 26.4815L228.219 26.5554C231.342 23.6892 239.858 14.4341 231.852 4.81839C224.791 -3.64161 212.67 4.52246 212.67 4.52246C211.493 5.35459 183.693 25.3165 187.131 61.4865C188.984 81.1155 200.716 96.7874 219.277 104.48C228.246 108.166 237.931 109.79 247.614 109.232C253.545 122.907 264.294 134.954 281.03 143.072C284.256 144.637 287.972 144.859 291.362 143.689C294.751 142.52 297.537 140.054 299.105 136.836C300.673 133.618 300.895 129.909 299.723 126.527C298.551 123.145 296.08 120.366 292.854 118.802L292.789 118.709ZM269.484 71.241C271.93 53.9234 280.752 42.0979 284.746 40.1747C285.969 40.5723 287.387 41.1824 287.526 41.8112C288.508 46.4156 282.587 58.8977 269.484 71.241Z"
              fill="#155FCC"
            />
            <mask
              id="mask0_2060_135"
              maskUnits="userSpaceOnUse"
              x="186"
              y="0"
              width="129"
              height="145"
            >
              <path
                d="M292.789 118.709C283.523 114.225 277.638 108.197 274.015 101.66C276.711 100.152 279.27 98.4099 281.66 96.4546C299.452 81.8368 318.578 57.2427 313.917 36.0233C312.509 29.6344 307.625 18.2527 290.751 13.8332C274.219 9.49682 256.844 23.4858 247.503 48.6439C243.488 59.3635 241.55 70.7468 241.795 82.1881C237.615 81.9818 233.501 81.0689 229.628 79.4882C223.141 76.7977 215.181 71.0375 214.023 58.8607C212.383 41.5616 223.419 30.5682 227.135 27.4246C227.135 27.4246 227.357 27.2582 227.71 26.953L228.312 26.4815L228.219 26.5554C231.342 23.6892 239.858 14.4341 231.852 4.81839C224.791 -3.64161 212.67 4.52246 212.67 4.52246C211.493 5.35459 183.693 25.3165 187.131 61.4865C188.984 81.1155 200.716 96.7874 219.277 104.48C228.246 108.166 237.931 109.79 247.614 109.232C253.545 122.907 263.674 136.351 281.03 143.072C282.894 143.893 284.897 144.354 286.933 144.432C289.99 144.428 292.957 143.392 295.348 141.491C297.74 139.59 299.416 136.938 300.102 133.965C300.789 130.992 300.447 127.875 299.13 125.121C297.814 122.368 295.602 120.14 292.854 118.802L292.789 118.709ZM269.484 71.241C271.93 53.9234 280.752 42.0979 284.746 40.1747C285.969 40.5723 287.387 41.1824 287.526 41.8112C288.508 46.4156 282.587 58.8977 269.484 71.241Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_2060_135)">
              <path
                d="M240.479 72.7481L241.758 82.1696L247.55 109.14C249.646 114.224 252.478 118.975 255.954 123.24L284.199 110.555L274.006 101.66L269.447 71.241L273.839 60.8671L239.061 58.1765L240.479 72.7481Z"
                fill="url(#paint0_linear_2060_135)"
              />
            </g>
            <path
              d="M269.484 71.2411C269.484 71.2411 261.31 80.9492 241.786 82.1697C219.657 83.5566 243.491 107.929 243.491 107.929L247.577 109.14C256.894 108.938 266.004 106.363 274.043 101.66C286.831 93.9121 269.484 71.2411 269.484 71.2411Z"
              fill="#155FCC"
            />
            <path
              d="M313.222 132.819C313.218 129.631 312.079 126.547 310.009 124.119C307.938 121.691 305.071 120.075 301.917 119.56L301.222 119.459H301.018H300.796C292.456 119.061 289.138 116.805 289.138 116.805C289.138 116.805 276.165 140.724 280.076 142.592C287.489 146.114 295.773 146.29 299.035 146.198H299.443C299.6 146.198 299.758 146.198 299.915 146.198H300.462C307.394 145.291 313.222 139.892 313.222 132.819Z"
              fill="#155FCC"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_2060_135"
              x1="256.279"
              y1="58.3244"
              x2="266.566"
              y2="116.979"
              gradientUnits="userSpaceOnUse"
            >
              <stop
                offset="0.14"
                stopColor="#155FCC"
              />
              <stop
                offset="0.21"
                stopColor="#144A9E"
              />
              <stop
                offset="0.29"
                stopColor="#123979"
              />
              <stop
                offset="0.33"
                stopColor="#12336B"
              />
              <stop
                offset="0.75"
                stopColor="#12336B"
              />
              <stop
                offset="0.97"
                stopColor="#155FCC"
              />
            </linearGradient>
            <clipPath id="clip0_2060_135">
              <rect
                width="522"
                height="218"
                fill="white"
                transform="translate(0 0.888916)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case "paper":
      return (
        <svg
          width="39"
          height="39"
          viewBox="0 0 39 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector"
            d="M29.25 0H15.8438C14.4976 0 13.4062 1.09139 13.4062 2.4375H26.8125V12.1875H36.5625V31.6875H28.0312V34.125H36.5625C37.9086 34.125 39 33.0336 39 31.6875V9.75061L29.25 0ZM29.25 9.75V3.44662L35.5522 9.75H29.25ZM2.4375 4.875C1.09139 4.875 0 5.96639 0 7.3125V36.5625C0 37.9086 1.09139 39 2.4375 39H23.1562C24.5024 39 25.5938 37.9086 25.5938 36.5625V14.6256L15.8438 4.875H2.4375ZM23.1562 36.5625H2.4375V7.3125H13.4062V17.0625H23.1562V36.5625ZM15.8438 14.625V8.32162L22.1459 14.625H15.8438Z"
            fill="#080808"
          />
        </svg>
      );
    case "check":
      return (
        <svg
          width="33"
          height="31"
          viewBox="0 0 33 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="31"
            height="31"
            rx="5"
            fill="white"
          />
          <path
            d="M32.1764 3.71676C32.6833 4.19495 32.9792 4.85444 32.9989 5.55023C33.0187 6.24602 32.7607 6.92116 32.2816 7.42719L17.3713 23.179C17.1297 23.4338 16.8393 23.6378 16.5173 23.7787C16.1953 23.9197 15.8483 23.9949 15.4967 23.9997C15.1451 24.0046 14.7961 23.9392 14.4703 23.8072C14.1446 23.6753 13.8486 23.4795 13.5999 23.2315L5.70619 15.3556C5.24141 14.8579 4.98838 14.1997 5.00041 13.5196C5.01244 12.8394 5.28858 12.1905 5.77067 11.7095C6.25276 11.2285 6.90314 10.953 7.58481 10.941C8.26648 10.929 8.92621 11.1814 9.425 11.6452L15.4067 17.6099L28.4576 3.82177C28.9368 3.31595 29.5978 3.02074 30.2952 3.00105C30.9925 2.98136 31.6692 3.2388 32.1764 3.71676Z"
            fill="black"
          />
        </svg>
      );

    case "tap":
      return (
        <svg
          width="57"
          height="57"
          viewBox="0 0 57 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="28.5"
            cy="28.5"
            r="28.5"
            fill="white"
          />
          <path
            d="M23.2354 30.1176V16.1176C23.2354 15.4623 23.4956 14.8339 23.959 14.3706C24.4223 13.9073 25.0507 13.647 25.7059 13.647C26.3612 13.647 26.9896 13.9073 27.4529 14.3706C27.9162 14.8339 28.1765 15.4623 28.1765 16.1176V28.4705M28.1765 27.647V24.3529C28.1765 23.6976 28.4368 23.0692 28.9001 22.6059C29.3635 22.1426 29.9919 21.8823 30.6471 21.8823C31.3024 21.8823 31.9308 22.1426 32.3941 22.6059C32.8574 23.0692 33.1177 23.6976 33.1177 24.3529V28.4705M33.1177 25.9999C33.1177 25.3447 33.378 24.7163 33.8413 24.2529C34.3046 23.7896 34.9331 23.5293 35.5883 23.5293C36.2435 23.5293 36.8719 23.7896 37.3353 24.2529C37.7986 24.7163 38.0589 25.3447 38.0589 25.9999V28.4705"
            stroke="#155FCC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M38.0588 27.6471C38.0588 26.9918 38.3191 26.3634 38.7824 25.9001C39.2458 25.4368 39.8742 25.1765 40.5294 25.1765C41.1847 25.1765 41.8131 25.4368 42.2764 25.9001C42.7397 26.3634 43 26.9918 43 27.6471V35.0588C43 37.6798 41.9588 40.1934 40.1055 42.0467C38.2522 43.9 35.7386 44.9412 33.1176 44.9412H29.8235H30.1661C28.5295 44.9415 26.9184 44.5353 25.4776 43.7591C24.0367 42.9829 22.8112 41.861 21.9111 40.4941L21.5882 40C21.0744 39.2116 19.2703 36.0668 16.176 30.5656C15.8605 30.0049 15.7762 29.3431 15.9411 28.7212C16.106 28.0992 16.507 27.5661 17.0588 27.2353C17.6469 26.8835 18.3355 26.7378 19.0156 26.8213C19.6958 26.9048 20.3287 27.2128 20.8141 27.6965L23.2353 30.1176M18.2941 13.6471L16.6471 12M16.6471 20.2353H15M33.1176 13.6471L34.7647 12M34.7647 18.5882H36.4118"
            stroke="#155FCC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "artistsBg":
      return (
        <svg
          width="1071"
          height="1331"
          viewBox="0 0 1071 1331"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M743.209 1136.61C562.291 1243.52 328.85 1183.33 221.805 1002.18C123.479 835.783 165.999 625.203 313.791 509.149C345.543 484.216 328.594 434.958 328.594 434.958C328.594 434.958 359.853 469.704 400.722 457.781C573.674 384.303 778.644 448.639 876.97 615.036C984.014 796.188 924.128 1029.71 743.209 1136.61Z"
            fill="#C4C4C4"
          />
          <path
            d="M168.559 75.0174C261.652 33.5864 370.749 75.5645 412.234 168.778C450.339 254.399 418.143 353.467 340.498 401.147C323.816 411.39 329.24 436.06 329.24 436.06C329.24 436.06 316.116 417.585 295.766 421.054C208.37 446.826 113.218 404.434 75.1127 318.813C33.6283 225.599 75.4657 116.448 168.559 75.0174Z"
            fill="#C4C4C4"
          />
        </svg>
      );
    case "mediaSource":
      return (
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="41"
            height="41"
            rx="20.5"
            fill="#FF4747"
          />
          <path
            d="M19.5105 32.3894L18.916 27.6339M19.5105 32.3894L23.6716 27.6339L23.0771 22.8783M19.5105 32.3894H31.3993V13.3672H21.8882L22.4827 18.1227M22.4827 18.1227L23.0771 22.8783M22.4827 18.1227H26.0493M23.0771 22.8783H26.0493"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.5667 13.3669H17.7278M13.5667 18.1224H18.3222M13.5667 22.878H18.9167M10 8.61133H21.2944L21.8889 13.3669L22.4833 18.1224L23.0778 22.878L23.6722 27.6335H10V8.61133Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    case "mark":
      return (
        <svg
          width="29"
          height="42"
          viewBox="0 0 29 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector"
            d="M14.5 19.975C13.1266 19.975 11.8094 19.435 10.8382 18.4739C9.86703 17.5128 9.32143 16.2092 9.32143 14.85C9.32143 13.4908 9.86703 12.1872 10.8382 11.2261C11.8094 10.265 13.1266 9.725 14.5 9.725C15.8734 9.725 17.1906 10.265 18.1618 11.2261C19.133 12.1872 19.6786 13.4908 19.6786 14.85C19.6786 15.523 19.5446 16.1895 19.2844 16.8113C19.0241 17.433 18.6427 17.998 18.1618 18.4739C17.6809 18.9498 17.11 19.3273 16.4818 19.5849C15.8535 19.8424 15.1801 19.975 14.5 19.975ZM14.5 0.5C10.6544 0.5 6.96623 2.01187 4.24695 4.70302C1.52767 7.39416 0 11.0441 0 14.85C0 25.6125 14.5 41.5 14.5 41.5C14.5 41.5 29 25.6125 29 14.85C29 11.0441 27.4723 7.39416 24.753 4.70302C22.0338 2.01187 18.3456 0.5 14.5 0.5Z"
            fill="white"
          />
        </svg>
      );
    case "dropdownArrow":
      return (
        <svg
          width="39"
          height="39"
          viewBox="0 0 39 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Vector">
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="#080808"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 19.5L19.5 36.5M19.5 36.5L2.00001 19.5M19.5 36.5L19.5 2.49999"
              stroke="black"
              stroke-opacity="0.2"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      );

    default:
      return <></>;
  }
};

export default SvgSelector;
